import React, { useMemo, useState } from 'react';
import { Card, AreaChart, Tabs } from '@multifarm/widget-components';

// Contracts
import { HistoricalValue } from '../../../common/contracts';
import { TooltipOptions } from '@multifarm/widget-components/dist/components/charts/contracts';

// Utils
import {
  formatDate,
  formatMoney,
  capitalizeFirstLetter,
  parseRangeChartData,
  getRangeOptions,
  formatTooltipMoney
} from '../../../common/utils';

// Hooks
import { useChartOptions } from '../../../common/hooks/useChartOptions';

// Static
import {
  RangeTypeEnum,
  BASE_CHART_KEYS,
  CHART_TOOLTIP_DATE_PRIMARY,
  DEFAULT_DATE_FORMAT,
  EXPANDED_DATE_FORMAT
} from '../../../common/static';

type TotalBribeVolumeChartProps = {
  data: HistoricalValue[];
};

const yTickFormatter = (tick: number): string => formatMoney(tick, 2, true);

const tooltipOptions: TooltipOptions = {
  formatTooltipKey: (tick: string): string => capitalizeFirstLetter(tick),
  formatTooltipValue: (tick: number): string => formatTooltipMoney(tick),
  formatCategory: (date: string) => formatDate(date, CHART_TOOLTIP_DATE_PRIMARY)
};

export const TotalBribeVolumeChart: React.FC<TotalBribeVolumeChartProps> = ({
  data
}) => {
  const [range, setRange] = useState(RangeTypeEnum['14D']);

  /**
   * Memorising the available ranges.
   */
  const ranges = useMemo(() => {
    return getRangeOptions(data);
  }, [data]);

  /**
   * Memorising the ranged chart data.
   */
  const chartData = useMemo(() => {
    return parseRangeChartData(data, range);
  }, [range, data]);

  const defaultOptions = useMemo(
    () => ({
      data: chartData,
      formatter: yTickFormatter,
      options: {
        xAxis: {
          minTickGap: range === RangeTypeEnum.MAX ? 35 : 10
        }
      }
    }),
    [chartData]
  );

  const chartOptions = useChartOptions(defaultOptions);

  console.log('Chart options', chartOptions);

  return (
    <div className="page-overview-total-market-value-chart">
      <Card
        className="multibribes-card"
        title="Total Bribe Volume"
        headerComponent={
          <Tabs
            tabs={ranges}
            value={range}
            onChange={(value: string): void => {
              setRange(value as RangeTypeEnum);
            }}
            large
          />
        }
      >
        <AreaChart
          data={chartData}
          dataKeys={BASE_CHART_KEYS}
          isLoading={false}
          yTickFormatter={yTickFormatter}
          xTickFormatter={(tick: string): string =>
            formatDate(
              tick,
              range === RangeTypeEnum.MAX
                ? EXPANDED_DATE_FORMAT
                : DEFAULT_DATE_FORMAT
            )
          }
          colors={['#416bff']}
          gradientColors={true}
          chartOptions={chartOptions}
          tooltipOptions={tooltipOptions}
          className="borderless-grid"
        />
      </Card>
    </div>
  );
};
