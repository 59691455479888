import React from 'react';
import { Card, Link, List, ListItem } from '@multifarm/widget-components';

// Components
import { DetailsCard } from '../../../cards/DetailsCard';

// Styles
import './details-cards.scss';

type DetailsCardsProps = {
  details: {
    name: string;
    address: string;
    blockchain?: string;
    website?: string;
    blo?: string;
    about?: string;
    riskDescription?: string;
    investmentThesis?: string;
  };
};

export const DetailsCards: React.FC<DetailsCardsProps> = ({ details }) => {
  return (
    <div className="page-bribe-markets-governance-power-details-cards">
      <Card className="multibribes-card" title={`Overview: ${details.name}`}>
        <div className="page-platform-details-overview-lists">
          <List className="multibribes-list left-list">
            {/* <ListItem title="Blockchain" value={details.blockchain || 'N/A'} /> */}
            <ListItem
              title="Website"
              value={details.website ? <Link url={details.website} /> : 'N/A'}
            />
          </List>
          <List className="multibribes-list right-list">
            <ListItem title="Token Name" value={details.name} />
            <ListItem title="Token Address" value={details.address} />
          </List>
        </div>
      </Card>
      {(details.about ||
        details.riskDescription ||
        details.investmentThesis) && (
        <div className="other-details-cards">
          {details.about && (
            <DetailsCard
              title="About"
              details={{
                description: details.about
              }}
            />
          )}
          {details.riskDescription && (
            <DetailsCard
              title="Risk description"
              details={{
                description: details.riskDescription
              }}
            />
          )}
          {details.investmentThesis && (
            <DetailsCard
              title="Multifarm investment thesis"
              details={{
                description: details.investmentThesis
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
