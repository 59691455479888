import React, { useEffect, useMemo, useState } from 'react';
import { Select } from '@multifarm/widget-components';

// Components
import {
  Statistics,
  GovernancePowerChart,
  TotalBribesOverTimeChart,
  VoteRewardsChart
} from '../../components/plaftorm-details';

// Hooks
import { useMultibribesContext } from '../../context/multibribes.context';

// Models
import { Platform } from '../../common/models/domain';

// Styles
import './platform-details.scss';

export const PlatformDetails: React.FC = () => {
  const { platforms, activePlatform, setActivePlatform } =
    useMultibribesContext();
  const [platform, setPlatform] = useState<Platform | undefined>(platforms[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!activePlatform && platform) return;
    setPlatform(activePlatform || platforms[0]);
    // Reset the context state
    if (activePlatform) {
      setActivePlatform(undefined);
    }
  }, [platforms]);

  // Memorising the platform options
  const PLATFORM_OPTIONS = useMemo(() => {
    return platforms.map((platform) => ({
      value: platform.id,
      label: platform.platform
    }));
  }, [platforms]);

  /**
   * Select platform handler.
   * @param id - Platform's id.
   */
  const selectPlatform = (id: string): void => {
    const selectedPlatform = platforms.find((platform) => platform.id === id);
    setPlatform(selectedPlatform);
  };

  return (
    <div className="page-platform-details">
      <Select
        options={PLATFORM_OPTIONS}
        value={platform?.id}
        onChange={selectPlatform}
        className="platform-select"
      />
      <Statistics statistics={platform} />
      <div className="page-platform-details-charts">
        <VoteRewardsChart data={platform?.tvlStakedHistorical || []} />
        <TotalBribesOverTimeChart />
        <GovernancePowerChart />
      </div>
      {/* Commented out for the time being. This may return so let's keep it here until final decision */}
      {/* <PlatformOverview platform={platform} />
      <DetailsCards platform={platform} /> */}
    </div>
  );
};
