import React from 'react';
import clsx from 'clsx';
// Icons
import { CaretUpIcon } from '../../../assets/icons';
// Styles
import './select.scss';
export var Select = function (_a) {
    var options = _a.options, onChange = _a.onChange, value = _a.value, placeholder = _a.placeholder, className = _a.className;
    return (React.createElement("div", { className: clsx('multifarm-widget-select-container', className) },
        React.createElement("select", { value: value, onChange: function (e) { return onChange(e.target.value); }, className: "multifarm-widget-select" },
            placeholder && React.createElement("option", { value: "" }, placeholder),
            options.map(function (row, index) { return (React.createElement("option", { key: index, value: row.value }, row.label)); })),
        React.createElement(CaretUpIcon, null)));
};
