import React from 'react';
import { InfoTooltip } from '@multifarm/widget-components';

// Contracts
import { GaugePresentationData } from '../../../../common/models/domain/gauge.model';

// Utils
import { formatPercent } from '../../../../common/utils';

export const GAUGES_TABLE = {
  staticColumns: [
    {
      name: 'Gauge',
      key: 'gaugeName',
      format: (gaugeName: string): string => gaugeName || 'N/A',
      align: 'left' as const,
      wrap: true,
      width: '20%'
    },
    {
      name: 'Duration',
      key: 'presentation',
      format: (presenstation: GaugePresentationData): string =>
        presenstation?.duration || 'N/A',
      align: 'left' as const,
      wrap: true,
      width: '10%'
    },
    {
      name: 'Protocol',
      key: 'platform',
      format: (platform: string): string => platform || 'N/A',
      align: 'left' as const,
      wrap: true,
      width: '10%'
    },
    {
      name: 'Value per vote',
      key: 'presentation',
      // The key which can be used for sorting the gauges over the API.
      sortKey: 'value_per_vote',
      sortLabel: 'Value per vote',
      format: (
        presenstation: GaugePresentationData
      ): React.ReactNode | string => presenstation.valuePerVote,
      align: 'left' as const,
      wrap: true,
      width: '10%'
    },
    {
      name: (
        <div className="apr-container">
          APR{' '}
          <InfoTooltip
            content={
              <div>
                <p>
                  Annualized Percentage Rate taking round durations into account
                  with following assumptions:
                </p>
                <p>- Vote count stays same throughout the year</p>
                <p>- Vote token price stay same throughout the year</p>
                <p>- $ rewards amount will be same for each round</p>
              </div>
            }
          />
        </div>
      ),
      key: 'averageApr',
      // The key which can be used for sorting the gauges over the API.
      sortKey: 'average_apr',
      sortLabel: 'APR',
      format: (averageApr: number): string => formatPercent(averageApr),
      align: 'left' as const,
      wrap: true,
      width: '10%'
    }
  ]
};
