import React from 'react';

// Components
import { MenuTab } from './MenuTab';

// Assets
import { OverviewIcon, BribeMarketsIcon } from '../../../assets/icons';

// Static
import { TabEnum } from '../../../common/static';

// Styles
import './menu-tabs.scss';

type MenuTabsProps = {
  activeTab: TabEnum;
  onTab: (tab: TabEnum) => void;
};

export const MenuTabs: React.FC<MenuTabsProps> = ({ activeTab, onTab }) => {
  return (
    <div className="menu-tabs">
      <MenuTab
        title="Vote Incentives"
        onClick={(): void => onTab(TabEnum.BRIBE_MARKETS)}
        icon={<BribeMarketsIcon />}
        active={activeTab === TabEnum.BRIBE_MARKETS}
      />
      <MenuTab
        title="Overview"
        onClick={(): void => onTab(TabEnum.OVERVIEW)}
        icon={<OverviewIcon />}
        active={activeTab === TabEnum.OVERVIEW}
      />
      {/* TODO: Platform details will be returned at one point. */}
      {/* <MenuTab
        title="Platform details"
        onClick={(): void => onTab(TabEnum.PLATFORM_DETAILS)}
        icon={<PlatformDetailsIcon />}
        active={activeTab === TabEnum.PLATFORM_DETAILS}
      /> */}
    </div>
  );
};
