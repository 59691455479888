// Libs
import { useMemo } from 'react';
import { ChartOptions } from '@multifarm/widget-components/dist/components/charts/contracts';

// Utils
import { calcAxisWidth } from '../utils';

const defaultOptions: ChartOptions = {
  grid: { enable: true, stroke: 'var(--grid-stroke)', opacity: 0.2 }
};

type IChartOptions = {
  data?: any[];
  formatter?: (data: number) => string;
  options?: ChartOptions;
  minWidth?: number;
};

export const useChartOptions = ({
  data = [],
  formatter = (tick: number): string => tick.toString(),
  options = {},
  minWidth = 60
}: IChartOptions = {}): ChartOptions => {
  const yAxisWidth = calcAxisWidth(data, formatter, minWidth);
  return useMemo(
    () => ({
      ...defaultOptions,
      yAxis: { width: yAxisWidth },
      ...options
    }),
    [yAxisWidth, options]
  );
};
