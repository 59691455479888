import React from 'react';
import clsx from 'clsx';
// Styles
import './tabs.scss';
export var Tabs = function (_a) {
    var tabs = _a.tabs, value = _a.value, onChange = _a.onChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b, className = _a.className, tabClassName = _a.tabClassName, _c = _a.large, large = _c === void 0 ? false : _c;
    return (React.createElement("div", { className: clsx('multifarm-widget-tabs-container', {
            'multifarm-widget-tabs-container-scroll': large
        }) },
        React.createElement("div", { "data-disabled": disabled, className: clsx('multifarm-widget-tabs', className, {
                'multifarm-widget-tabs-scroll': large
            }) }, tabs.map(function (tab, index) { return (React.createElement("div", { key: index, className: clsx('multifarm-widget-tabs-tab', tabClassName), "data-active": value === tab.value, onClick: function () { return onChange(tab.value); } }, tab.name)); }))));
};
