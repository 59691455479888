import React from 'react';
import clsx from 'clsx';
import { CartesianGrid, Line, LineChart as ReLineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// Components
import { LoadingPlaceholder, NoDataPlaceholder } from '../placeholders';
import { Legends, Tooltip as MultifarmTooltip } from './common';
// Utils
import { buildChartOptions } from '../../common/utils';
// Styles
import './chart.scss';
export var LineChart = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var data = _a.data, dataKeys = _a.dataKeys, yTickFormatter = _a.yTickFormatter, xTickFormatter = _a.xTickFormatter, _u = _a.xAxisDataKey, xAxisDataKey = _u === void 0 ? 'date' : _u, legends = _a.legends, colors = _a.colors, isLoading = _a.isLoading, _v = _a.initialHeight, initialHeight = _v === void 0 ? 200 : _v, chartOptions = _a.chartOptions, _w = _a.showTooltip, showTooltip = _w === void 0 ? true : _w, tooltipOptions = _a.tooltipOptions, className = _a.className;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    chartOptions = buildChartOptions(chartOptions);
    return (React.createElement("div", { className: "multifarm-widget-chart-container", "data-legends": !!(legends === null || legends === void 0 ? void 0 : legends.length) },
        React.createElement("div", { className: clsx('multifarm-widget-chart', className), "data-height": initialHeight },
            React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                React.createElement(ReLineChart, { data: data },
                    React.createElement(YAxis, { type: "number", domain: ['auto', 'auto'], width: (_b = chartOptions.yAxis) === null || _b === void 0 ? void 0 : _b.width, tickFormatter: yTickFormatter, axisLine: (_c = chartOptions.yAxis) === null || _c === void 0 ? void 0 : _c.line, stroke: (_d = chartOptions.yAxis) === null || _d === void 0 ? void 0 : _d.stroke, interval: (_e = chartOptions.yAxis) === null || _e === void 0 ? void 0 : _e.interval, tick: (_f = chartOptions.yAxis) === null || _f === void 0 ? void 0 : _f.tick, tickLine: (_g = chartOptions.yAxis) === null || _g === void 0 ? void 0 : _g.tickLine, reversed: (_h = chartOptions.yAxis) === null || _h === void 0 ? void 0 : _h.reversed, minTickGap: (_j = chartOptions.yAxis) === null || _j === void 0 ? void 0 : _j.minTickGap, padding: { top: 20, bottom: 20 } }),
                    React.createElement(XAxis, { dataKey: xAxisDataKey, tickFormatter: xTickFormatter, axisLine: (_k = chartOptions.xAxis) === null || _k === void 0 ? void 0 : _k.line, stroke: (_l = chartOptions.xAxis) === null || _l === void 0 ? void 0 : _l.stroke, width: (_m = chartOptions.xAxis) === null || _m === void 0 ? void 0 : _m.width, tick: (_o = chartOptions.xAxis) === null || _o === void 0 ? void 0 : _o.tick, tickLine: (_p = chartOptions.xAxis) === null || _p === void 0 ? void 0 : _p.tickLine, reversed: (_q = chartOptions.xAxis) === null || _q === void 0 ? void 0 : _q.reversed, minTickGap: (_r = chartOptions.xAxis) === null || _r === void 0 ? void 0 : _r.minTickGap, padding: { left: 20, right: 20 } }),
                    ((_s = chartOptions.grid) === null || _s === void 0 ? void 0 : _s.enable) && (React.createElement(CartesianGrid, { vertical: chartOptions.grid.vertical, stroke: chartOptions.grid.stroke, opacity: chartOptions.grid.opacity })),
                    ((_t = chartOptions.referenceLine) === null || _t === void 0 ? void 0 : _t.enable) && (React.createElement(ReferenceLine, { y: chartOptions.referenceLine.y, stroke: chartOptions.referenceLine.stroke, strokeOpacity: chartOptions.referenceLine.strokeOpacity, strokeDasharray: chartOptions.referenceLine.strokeDasharray })),
                    showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) {
                            var _a, _b;
                            var payload = (_b = (_a = props.payload) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.payload;
                            if (!payload)
                                return React.createElement(React.Fragment, null);
                            return (React.createElement(MultifarmTooltip, { data: payload, keys: dataKeys, colors: colors, revertKeys: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.revertKeys, formatTooltipKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipKey, formatTooltipValue: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipValue, categoryKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.categoryKey, formatCategory: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatCategory }));
                        } })),
                    dataKeys.map(function (key, index) { return (React.createElement(Line, { key: "line-".concat(index), dataKey: key, stroke: colors[index], type: "monotoneX", unit: "M", strokeLinecap: "round", strokeWidth: 2, lightingColor: "#000", dot: false, legendType: "none" })); })))),
        legends && React.createElement(Legends, { legends: legends, colors: colors })));
};
