import React, { useEffect, useMemo, useState } from 'react';
import { Select } from '@multifarm/widget-components';
import clsx from 'clsx';

// Components
import { Input } from '../../../form/Input';

// Assets
import { FilterIcon, MagnifiyingGlassIcon } from '../../../../assets/icons';

// Utils
import { useDebounce } from '../../../../common/hooks/useDebounce';
import { useMultibribesContext } from '../../../../context/multibribes.context';

type GaugesOverviewHeaderProps = {
  searchHandle: (query: string) => void;
  // Vote tokens for the certain gauge
  voters: string[];
  // Active vote token for the certain gauge
  activeVoters: string[];
  // Callback to handle the voter click
  voterHandle?: (voter: string) => void;
  // Platform to filter by
  platformName?: string;
  filterHandle: (platformName: string) => void;
};

export const GaugesOverviewHeader: React.FC<GaugesOverviewHeaderProps> = ({
  searchHandle,
  voters,
  activeVoters,
  voterHandle,
  platformName,
  filterHandle
}) => {
  const { platforms } = useMultibribesContext();

  /**
   * Memorising platform filter options from the table columns.
   */
  const platformFilterOptions = useMemo(() => {
    return platforms.map((platform) => ({
      label: platform.platform,
      value: platform.platform
    }));
  }, [platforms]);

  const [searchInput, setSearchInput] = useState('');
  const searchValue = useDebounce(searchInput, 300);

  /**
   * Hook for triggering the search handle.
   */
  useEffect(() => {
    if (!searchHandle) {
      return;
    }
    searchHandle(searchValue);
  }, [searchValue]);

  return (
    <div className="page-bribe-markets-gagues-overview-header">
      {platformFilterOptions.length > 0 && (
        <div className="filter-dropdown">
          <div className="filter-dropdown-title">
            <FilterIcon />
            <span className="multifarm-heading">Filter by protocol</span>
          </div>
          <Select
            options={platformFilterOptions}
            value={platformName}
            placeholder="None"
            onChange={(platformName): void => {
              filterHandle(platformName);
            }}
            className="filter-dropdown-select"
          />
        </div>
      )}
      {voters.length > 0 && (
        <div className="voters-container">
          <span className="multifarm-heading">Voter</span>
          {voters.map((voter: string) => (
            <div
              key={voter}
              className={clsx('voters-pill clickable', {
                'voters-pill-active': activeVoters.includes(voter)
              })}
              onClick={(): void => {
                if (voterHandle) {
                  voterHandle(voter);
                }
              }}
            >
              {voter}
            </div>
          ))}
        </div>
      )}
      <div className="search-container">
        <MagnifiyingGlassIcon />
        <span className="multifarm-heading">Search:</span>
        <Input
          placeholder="Gauge Name"
          onChange={setSearchInput}
          value={searchInput}
        />
      </div>
    </div>
  );
};
