import dateFormat from 'date-fns/format';
import dateParse from 'date-fns/parse';

// Static
import {
  API_DATE_FORMAT_SECONDARY,
  CHART_TOOLTIP_DATE_PRIMARY,
  DEFAULT_DATE_FORMAT
} from '../static';

// Utils
import { isNotEmpty } from './global.utils';

/**
 * Formats the number to the string value.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @param includeZero - Should the zero be a valid result?
 * @returns Formatted money string.
 */
export const formatNumber = (
  number?: number,
  fixed = 2,
  includeZero = false
): string => {
  if (!number || isNaN(Number(number))) {
    return includeZero ? (0).toFixed(fixed) : 'N/A';
  }

  const isNegative = number < 0;
  number = Math.abs(number);
  let formattedNumber: string;

  const regex = /\.?0+$/;

  if (number >= 1000000000) {
    formattedNumber =
      (number / 1000000000).toFixed(fixed).replace(regex, '') + 'B';
  } else if (number >= 1000000) {
    formattedNumber =
      (number / 1000000).toFixed(fixed).replace(regex, '') + 'M';
  } else if (number >= 1000) {
    formattedNumber = (number / 1000).toFixed(fixed).replace(regex, '') + 'k';
  } else {
    formattedNumber = number.toFixed(fixed).replace(regex, '');
  }

  if (isNegative) {
    formattedNumber = '-' + formattedNumber;
  }

  return formattedNumber;
};

/**
 * Formats the number to the string money value.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @param includeZero - Should the zero be a valid result?
 * @returns Formatted money string.
 */
export const formatMoney = (
  number?: number,
  fixed = 2,
  includeZero = false
): string => {
  if (!number || isNaN(Number(number))) {
    return includeZero ? `$ ${(0).toFixed(fixed)}` : 'N/A';
  }
  return `$ ${formatNumber(number, fixed)}`;
};

/**
 * Formats the given number to the percentage string.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @returns Formated percent string.
 */
export function formatPercent(number?: number, fixed = 2): string {
  if (!isNotEmpty(number) || isNaN(Number(number))) return 'N/A';
  return number || number === 0 ? `${Number(number).toFixed(fixed)}%` : 'N/A';
}

/**
 * Formats the given date by the given locale and options.
 * @param date - Date string, number or object.
 * @param format - Date format.
 * @param parseFormat - Parse format.
 * @returns Formated string.
 */
export const formatDate = (
  date: string | number | Date,
  format: string = DEFAULT_DATE_FORMAT,
  parseFormat: string = API_DATE_FORMAT_SECONDARY
): string => {
  try {
    const dateToFormat =
      typeof date === 'string'
        ? dateParse(date, parseFormat, new Date())
        : date;
    return dateFormat(dateToFormat, format);
  } catch (e) {
    return '';
  }
};

/**
 * Formats the given date for chart tooltips.
 * @param date - Date string or number.
 * @returns Formated string.
 */
export const formatChartTooltipDate = (date: string | number): string => {
  return date ? formatDate(date, CHART_TOOLTIP_DATE_PRIMARY) : 'N/A';
};

export const formatTooltipMoney = (
  value: number | string,
  currency = 'USD'
): string => {
  return formatTooltipNumber(value, { style: 'currency', currency });
};

export const formatTooltipNumber = (
  value: string | number,
  options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 8
  }
): string => {
  const valueAsNumber = !Number.isNaN(Number(value)) ? Number(value) : 0;

  return valueAsNumber.toLocaleString('en', options);
};
