// Contracts
import { HistoricalValue, RawTotalBribeStatistics } from '../../contracts';

// Utils
import { reverse } from '../../utils';

export class TotalBribeStatistics {
  gaugesCount: number;
  marketsCount: number;
  platformsCount: number;
  totalVolume: number;
  totalVolumeHistorical: HistoricalValue[];
  tvlStaked: number;
  tvlStakedHistorical: HistoricalValue[];

  constructor(data: RawTotalBribeStatistics) {
    this.gaugesCount = data.gauges_count;
    this.marketsCount = data.markets_count;
    this.platformsCount = data.platforms_count;
    this.totalVolume = data.total_volume;
    this.totalVolumeHistorical = reverse(data.total_volume_historical);
    this.tvlStaked = data.tvl_staked;
    this.tvlStakedHistorical = reverse(data.tvl_staked_historical);
  }
}
