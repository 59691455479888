import React from 'react';
import { Card, AreaChart } from '@multifarm/widget-components';

// Utils
import {
  capitalizeFirstLetter,
  formatDate,
  formatPercent
} from '../../../common/utils';

// Hooks
import { useChartOptions } from '../../../common/hooks/useChartOptions';

// Static
import { PLATFORM_DETAILS_CHART } from '../platform-details.static';

const yTickFormatter = (tick: number): string => formatPercent(tick / 10000);

export const TotalBribesOverTimeChart: React.FC = () => {
  const chartOptions = useChartOptions({
    data: PLATFORM_DETAILS_CHART.data,
    formatter: yTickFormatter
  });

  return (
    <div className="page-platform-total-bribes-over-time-chart">
      <Card className="multibribes-card" title="Total Bribes Over Time">
        <AreaChart
          data={PLATFORM_DETAILS_CHART.data}
          dataKeys={PLATFORM_DETAILS_CHART.keys}
          yTickFormatter={yTickFormatter}
          xTickFormatter={(tick: string): string => formatDate(tick)}
          colors={['#32BBFF']}
          gradientColors={true}
          chartOptions={chartOptions}
          tooltipOptions={{
            formatTooltipKey: (tick: string): string =>
              capitalizeFirstLetter(tick),
            formatTooltipValue: (tick: number): string =>
              formatPercent(tick / 10000)
          }}
          className="borderless-grid"
        />
      </Card>
    </div>
  );
};
