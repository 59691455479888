import useSwr from 'swr';

// API - Service
import { fetcher } from '../../services';

// Contracts
import { BaseAPIResponse } from '../../contracts';

/**
 * @returns Available tokens used for voting.
 */
export const useVoteTokens = (): BaseAPIResponse<string[] | undefined> => {
  const { data, error } = useSwr('/vote_tokens', fetcher);

  const isLoading = !data && !error;

  return {
    isLoading,
    data: data?.data
  };
};
