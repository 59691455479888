import React from 'react';
import { Card } from '@multifarm/widget-components';

// Assets
import { GoToIcon } from '../../../../assets/icons';

// Stlyes
import './liquid-wrapper.scss';

type LiquidWrapperProps = {
  wrapper: {
    name: string;
    icon?: React.ReactNode;
    appUrl?: string;
  };
};

export const LiquidWrapper: React.FC<LiquidWrapperProps> = ({ wrapper }) => {
  return (
    <div className="page-bribe-markets-governance-power-liquid-wrapper">
      <Card className="multibribes-card liquid-wrapper">
        <div className="liquid-wrapper-left-container">
          {wrapper.icon}
          <span className="wrapper-name">{wrapper.name}</span>
          <GoToIcon />
        </div>
        <div className="liquid-wrapper-right-container">
          <div className="main-stat">280,132,967.84645 veCRV (53.36%)</div>
          <div className="other-stats">
            <div className="stat">
              <span>veCRV holder: 0x989a...ad80</span>
              <GoToIcon />
            </div>
            <div className="stat">
              <span>Token: 0x62b9...0aa7</span>
              <GoToIcon />
            </div>
            <div className="stat">
              <span>
                Peg for 10k: <span className="highlight">93.58%</span>
              </span>
            </div>
            <div className="stat">
              <span>via1inch</span>
              <GoToIcon />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
