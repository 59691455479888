import useSwr from 'swr';

// API - Service
import { fetcher } from '../../services/api';

// Contracts
import { RawPlatform, BaseAPIResponse } from '../../contracts';

// Models
import { Platform } from '../../models/domain';

export const usePlatforms = (): BaseAPIResponse<Platform[] | undefined> => {
  const { data, error } = useSwr('/platforms', fetcher);

  let response;
  const isLoading = !data && !error;

  if (data) {
    response = data.map(
      (rawPlatform: RawPlatform) => new Platform(rawPlatform)
    );
  }

  return {
    isLoading,
    data: response
  };
};
