// Models
import { AssetToken } from './asset-token.model';

// Contracts
import { HistoricalValue, RawPlatform } from '../../contracts';

// Utils
import { reverse } from '../../utils';

export class Platform {
  id: string;
  about: string;
  aboutWebsite: string;
  appUrl: string;
  auditLink?: string;
  assetTokens: AssetToken[];
  blockchain?: string;
  coverVaultWebsite: string;
  depositFee?: number;
  gaugesCount: number;
  howToUse: string;
  insureVaultWebsite: string;
  marketsCount: number;
  performanceFee?: number;
  platform: string;
  rewards: number;
  rewardsAllTime: number;
  riskDescription: string;
  strategy: string;
  strategyWebsite: string;
  strategyAddress: string;
  totalVolume: number;
  tvlStaked: number;
  tvlStakedHistorical: HistoricalValue[];
  vaultApy?: number;
  yieldType?: string;
  withdrawalFee?: number;

  constructor(data: RawPlatform) {
    this.id = data._id;
    this.about = data.about;
    this.aboutWebsite = data.about_website;
    this.appUrl = data.app_url;
    this.auditLink = data.audit_link;
    this.assetTokens = (data.asset_tokens || []).map(
      (rawAssetToken) => new AssetToken(rawAssetToken)
    );
    this.blockchain = data.blockchain;
    this.coverVaultWebsite = data.cover_vault_website;
    this.depositFee = data.deposit_fee;
    this.gaugesCount = data.gauges_count;
    this.howToUse = data.how_to_use;
    this.insureVaultWebsite = data.insure_vault_website;
    this.marketsCount = data.markets_count;
    this.performanceFee = data.performance_fee;
    this.platform = data.platform;
    this.rewards = data.rewards;
    this.rewardsAllTime = data.rewards_all_time;
    this.riskDescription = data.risk_description;
    this.strategy = data.strategy;
    this.strategyAddress = data.strategy_address;
    this.strategyWebsite = data.strategy_website;
    this.totalVolume = data.total_volume;
    this.tvlStaked = data.tvl_staked;
    this.tvlStakedHistorical = data.tvl_staked_historical
      ? reverse(data.tvl_staked_historical)
      : [];
    this.vaultApy = data.vault_apy;
    this.yieldType = data.yield_type;
    this.withdrawalFee = data.withdrawal_fee;
  }

  hasAbout(): boolean {
    return !!this.about || !!this.aboutWebsite;
  }

  hasStrategy(): boolean {
    return !!this.strategy || !!this.strategyWebsite || !!this.strategyAddress;
  }

  hasRisk(): boolean {
    return (
      !!this.riskDescription ||
      !!this.coverVaultWebsite ||
      !!this.insureVaultWebsite
    );
  }

  hasHowToUse(): boolean {
    return !!this.howToUse || !!this.appUrl;
  }

  hasAssetTokens(): boolean {
    return this.assetTokens.length > 0;
  }
}
