import useSwr from 'swr';

// API - Service
import { fetcher } from '../../services/api';

// Contracts
import { BaseAPIResponse, RawTotalBribeStatistics } from '../../contracts';

// Models
import { TotalBribeStatistics } from '../../models/domain';

export const useTotalBribeStats = (): BaseAPIResponse<
  TotalBribeStatistics | undefined
> => {
  const { data, error } = useSwr('/total_bribe_stats', fetcher);

  let response;
  const isLoading = !data && !error;

  if (data) {
    response = new TotalBribeStatistics(data as RawTotalBribeStatistics);
  }

  return {
    isLoading,
    data: response
  };
};
