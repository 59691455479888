import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

// Components
import { Background } from './components/background/Background';
import { Header } from './components/header/Header';
import { Footer } from './components/footer';
import { Menu } from './components/menu/Menu';
import { BribeMarkets, Overview, PlatformDetails } from './pages';

// Hooks - API
import { usePlatforms } from './common/hooks/api';
import { useVoteTokens } from './common/hooks/api/useVoteTokens';

// Hooks
import { useMultibribesContext } from './context/multibribes.context';

// Static
import { TabEnum } from './common/static';

// Styles
import './multi-bribes.scss';

export const TabPageMap: Record<TabEnum, React.FC> = {
  [TabEnum.BRIBE_MARKETS]: BribeMarkets,
  [TabEnum.OVERVIEW]: Overview,
  [TabEnum.PLATFORM_DETAILS]: PlatformDetails
};

export const MultiBribes: React.FC = () => {
  const [activeTab, setActiveTab] = useState(TabEnum.BRIBE_MARKETS);
  const [menuOpen, setMenuOpen] = useState(true);

  const { data: apiPlatforms } = usePlatforms();
  const { data: apiVoteTokens } = useVoteTokens();

  const { platforms, setPlatforms, activePlatform, voteTokens, setVoteTokens } =
    useMultibribesContext();

  /**
   * A hook to load initial platform from the API to the context.
   */
  useEffect(() => {
    if (platforms.length || !apiPlatforms) {
      return;
    }
    setPlatforms(apiPlatforms);
  }, [apiPlatforms]);

  /**
   * A hook to load initial vote tokens from the API to the context.
   */
  useEffect(() => {
    if (voteTokens.length || !apiVoteTokens) {
      return;
    }
    setVoteTokens(apiVoteTokens);
  }, [apiVoteTokens]);

  /**
   * A hook to switch to the platform details page when a platform is selected.
   */
  useEffect(() => {
    if (!activePlatform || activeTab === TabEnum.PLATFORM_DETAILS) {
      return;
    }
    // TODO: Platform details will be returned at one point.
    // setActiveTab(TabEnum.PLATFORM_DETAILS);
  }, [activePlatform]);

  const Page = TabPageMap[activeTab];
  return (
    <div className="multibribes">
      <Background />
      <div className="multibribes-content">
        <Header
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          activeTab={activeTab}
        />

        <div className="multibribes-page-container">
          <Menu activeTab={activeTab} onTab={setActiveTab} open={menuOpen} />
          <div
            className={clsx('multibribes-page', {
              'multibribes-page-solo': !menuOpen
            })}
          >
            <Page />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
