var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Bar, ComposedChart as ReComposedChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Line, Area, Label } from 'recharts';
// Components
import { LoadingPlaceholder, NoDataPlaceholder } from '../placeholders';
import { Legends, Tooltip as MultifarmTooltip } from './common';
// Utils
import { buildChartOptions } from '../../common/utils';
// Styles
import './chart.scss';
/**
 * A chart similar to composed chart but with multiple Y axis.
 */
export var MultipleDomainChart = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var data = _a.data, dataOptions = _a.dataOptions, leftYTickFormatter = _a.leftYTickFormatter, rightYTickFormatter = _a.rightYTickFormatter, xTickFormatter = _a.xTickFormatter, _1 = _a.xAxisDataKey, xAxisDataKey = _1 === void 0 ? 'date' : _1, legends = _a.legends, isLoading = _a.isLoading, _2 = _a.initialHeight, initialHeight = _2 === void 0 ? 200 : _2, chartOptions = _a.chartOptions, barOptions = _a.barOptions, _3 = _a.showTooltip, showTooltip = _3 === void 0 ? true : _3, tooltipOptions = _a.tooltipOptions, className = _a.className;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    chartOptions = buildChartOptions(chartOptions);
    var _4 = useMemo(function () {
        var dataKeys = [];
        var colors = [];
        if (dataOptions.line) {
            dataKeys = dataOptions.line.keys;
            colors = dataOptions.line.colors;
        }
        if (dataOptions.bar) {
            dataKeys = __spreadArray(__spreadArray([], dataKeys, true), dataOptions.bar.keys, true);
            colors = __spreadArray(__spreadArray([], colors, true), dataOptions.bar.colors, true);
        }
        if (dataOptions.area) {
            dataKeys = __spreadArray(__spreadArray([], dataKeys, true), dataOptions.area.keys, true);
            colors = __spreadArray(__spreadArray([], colors, true), dataOptions.area.colors, true);
        }
        return { dataKeys: dataKeys, colors: colors };
    }, [dataOptions]), dataKeys = _4.dataKeys, colors = _4.colors;
    return (React.createElement("div", { className: "multifarm-widget-chart-container", "data-legends": !!(legends === null || legends === void 0 ? void 0 : legends.length) },
        React.createElement("div", { className: clsx('multifarm-widget-chart', className), "data-height": initialHeight },
            React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                React.createElement(ReComposedChart, { data: data, barGap: 0 },
                    React.createElement(YAxis, { yAxisId: "left", type: "number", domain: ['auto', 'auto'], width: (_b = chartOptions.leftYAxis) === null || _b === void 0 ? void 0 : _b.width, tickFormatter: leftYTickFormatter, axisLine: (_c = chartOptions.leftYAxis) === null || _c === void 0 ? void 0 : _c.line, stroke: (_d = chartOptions.leftYAxis) === null || _d === void 0 ? void 0 : _d.stroke, interval: (_e = chartOptions.leftYAxis) === null || _e === void 0 ? void 0 : _e.interval, tick: (_f = chartOptions.leftYAxis) === null || _f === void 0 ? void 0 : _f.tick, tickLine: (_g = chartOptions.leftYAxis) === null || _g === void 0 ? void 0 : _g.tickLine, padding: { top: 20, bottom: 20 } }, ((_h = chartOptions.leftYAxis) === null || _h === void 0 ? void 0 : _h.label) && (React.createElement(Label, { value: chartOptions.leftYAxis.label.value, position: chartOptions.leftYAxis.label.position, style: chartOptions.leftYAxis.label.styles, angle: chartOptions.leftYAxis.label.angle, offset: chartOptions.leftYAxis.label.offset }))),
                    React.createElement(YAxis, { yAxisId: "right", orientation: "right", type: "number", domain: ['auto', 'auto'], width: (_j = chartOptions.rightYAxis) === null || _j === void 0 ? void 0 : _j.width, tickFormatter: rightYTickFormatter, axisLine: (_k = chartOptions.rightYAxis) === null || _k === void 0 ? void 0 : _k.line, stroke: (_l = chartOptions.rightYAxis) === null || _l === void 0 ? void 0 : _l.stroke, interval: (_m = chartOptions.rightYAxis) === null || _m === void 0 ? void 0 : _m.interval, tick: (_o = chartOptions.rightYAxis) === null || _o === void 0 ? void 0 : _o.tick, tickLine: (_p = chartOptions.rightYAxis) === null || _p === void 0 ? void 0 : _p.tickLine, padding: { top: 20, bottom: 20 } }, ((_q = chartOptions.rightYAxis) === null || _q === void 0 ? void 0 : _q.label) && (React.createElement(Label, { value: chartOptions.rightYAxis.label.value, position: chartOptions.rightYAxis.label.position, style: chartOptions.rightYAxis.label.styles, angle: chartOptions.rightYAxis.label.angle, offset: chartOptions.rightYAxis.label.offset }))),
                    React.createElement(XAxis, { dataKey: xAxisDataKey, tickFormatter: xTickFormatter, axisLine: (_r = chartOptions.xAxis) === null || _r === void 0 ? void 0 : _r.line, stroke: (_s = chartOptions.xAxis) === null || _s === void 0 ? void 0 : _s.stroke, width: (_t = chartOptions.xAxis) === null || _t === void 0 ? void 0 : _t.width, tick: (_u = chartOptions.xAxis) === null || _u === void 0 ? void 0 : _u.tick, tickLine: (_v = chartOptions.xAxis) === null || _v === void 0 ? void 0 : _v.tickLine, padding: { left: 20, right: 20 } }),
                    ((_w = chartOptions.grid) === null || _w === void 0 ? void 0 : _w.enable) && (React.createElement(CartesianGrid, { vertical: chartOptions.grid.vertical, stroke: chartOptions.grid.stroke, opacity: chartOptions.grid.opacity })),
                    ((_x = chartOptions.referenceLine) === null || _x === void 0 ? void 0 : _x.enable) && (React.createElement(ReferenceLine, { y: chartOptions.referenceLine.y, stroke: chartOptions.referenceLine.stroke, strokeOpacity: chartOptions.referenceLine.strokeOpacity, strokeDasharray: chartOptions.referenceLine.strokeDasharray })),
                    showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) {
                            var _a, _b;
                            var payload = (_b = (_a = props.payload) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.payload;
                            if (!payload)
                                return React.createElement(React.Fragment, null);
                            return (React.createElement(MultifarmTooltip, { data: payload, keys: dataKeys, colors: colors, revertKeys: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.revertKeys, formatTooltipKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipKey, formatTooltipValue: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipValue, categoryKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.categoryKey, formatCategory: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatCategory }));
                        } })), (_y = dataOptions.bar) === null || _y === void 0 ? void 0 :
                    _y.keys.map(function (key, index) {
                        var _a, _b, _c;
                        return (React.createElement(Bar, { yAxisId: (_a = dataOptions.bar) === null || _a === void 0 ? void 0 : _a.yAxisId, key: "bar-".concat(index), dataKey: key, fill: (_b = dataOptions.bar) === null || _b === void 0 ? void 0 : _b.colors[index], stackId: (_c = dataOptions.bar) === null || _c === void 0 ? void 0 : _c.stack, maxBarSize: barOptions === null || barOptions === void 0 ? void 0 : barOptions.maxBarSize, radius: barOptions === null || barOptions === void 0 ? void 0 : barOptions.radius }));
                    }), (_z = dataOptions.line) === null || _z === void 0 ? void 0 :
                    _z.keys.map(function (key, index) {
                        var _a, _b;
                        return (React.createElement(Line, { yAxisId: (_a = dataOptions.line) === null || _a === void 0 ? void 0 : _a.yAxisId, key: "line-".concat(index), dataKey: key, stroke: (_b = dataOptions.line) === null || _b === void 0 ? void 0 : _b.colors[index], type: "monotoneX", unit: "M", strokeLinecap: "round", strokeWidth: 2, lightingColor: "#000", dot: false, legendType: "none" }));
                    }), (_0 = dataOptions.area) === null || _0 === void 0 ? void 0 :
                    _0.keys.map(function (key, index) {
                        var _a, _b, _c;
                        return (React.createElement(Area, { yAxisId: (_a = dataOptions.area) === null || _a === void 0 ? void 0 : _a.yAxisId, key: "area-".concat(index), dataKey: key, fill: (_b = dataOptions.area) === null || _b === void 0 ? void 0 : _b.colors[index], stackId: (_c = dataOptions.area) === null || _c === void 0 ? void 0 : _c.stack, connectNulls: true, type: "monotone" }));
                    })))),
        legends && React.createElement(Legends, { legends: legends, colors: colors })));
};
