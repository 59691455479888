import React from 'react';
import { Card } from '@multifarm/widget-components';

// Styles
import './details-card.scss';
import clsx from 'clsx';

type DetailsCardProps = {
  title: string;
  titleComponent?: string | React.ReactElement;
  details?: {
    website?: string | React.ReactElement;
    tokenContract?: string | React.ReactElement;
    vaultAddress?: string | React.ReactElement;
    coverVaultsUrl?: string | React.ReactElement;
    insureVaultsUrl?: string | React.ReactElement;
    appUrl?: string | React.ReactElement;
    description?: string | React.ReactElement;
  };
  className?: string;
};
export const DetailsCard: React.FC<DetailsCardProps> = ({
  title,
  titleComponent,
  details,
  className
}) => {
  return (
    <div className={clsx('multibribes-details-card', className)}>
      <Card
        className="multibribes-card"
        title={title}
        titleComponent={titleComponent}
      >
        <div className="multibribes-details-card-container">
          <div className="multibribes-details-card-links">
            {details?.website && <span>Website: {details.website}</span>}
            {details?.appUrl && <span>Go to App: {details.appUrl}</span>}
            {details?.tokenContract && (
              <span>Token Contract: {details.tokenContract}</span>
            )}
            {details?.vaultAddress && (
              <span>Vault Address: {details.vaultAddress}</span>
            )}
            {details?.coverVaultsUrl && (
              <span>Cover Vault: {details.coverVaultsUrl}</span>
            )}
            {details?.insureVaultsUrl && (
              <span>Insure Vault: {details.insureVaultsUrl}</span>
            )}
          </div>
          <div className="multibribes-details-card-description">
            {details?.description && <span>{details.description}</span>}
          </div>
        </div>
      </Card>
    </div>
  );
};
