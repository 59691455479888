import React, { useState, useMemo, useEffect } from 'react';
import { Select } from '@multifarm/widget-components';

// Components
import { PageTabs } from '../../components/tabs/PageTabs';
import { PageTabContract } from '../../components/tabs/PageTab';
import { Gauges } from '../../components/bribe-markets/gauges/Gauges';
import { GovernancePover } from '../../components/bribe-markets/governance-power/GovernancePower';

// Context
import { useMultibribesContext } from '../../context/multibribes.context';

// Static
import { BribeMarketsTabsEnum } from '../static';

// Assets
import { EcosystemIcon } from '../../assets/icons';

// Styles
import './bribe-markets.scss';

export const TabComponentMap: Record<BribeMarketsTabsEnum, React.FC> = {
  [BribeMarketsTabsEnum.INCENTIVES]: Gauges,
  [BribeMarketsTabsEnum.GOVERNANCE_POWER]: GovernancePover
};

export const BribeMarkets: React.FC = () => {
  const { voteTokens, activeVoteToken, setActiveVoteToken } =
    useMultibribesContext();
  const [activeTab, setActiveTab] = useState(BribeMarketsTabsEnum.INCENTIVES);
  const Component = TabComponentMap[activeTab];

  /**
   * A hook for selecting the initial vote token.
   */
  useEffect(() => {
    if (activeVoteToken || voteTokens.length === 0) return;
    const defaultToken = voteTokens.find((value) => value === 'veCRV');
    setActiveVoteToken(defaultToken || voteTokens[0]);
  }, [voteTokens]);

  /**
   * Memorising the vote tokens dropdown options.
   */
  const voteTokenOptions = useMemo(
    () =>
      voteTokens.map((voteToken) => ({ label: voteToken, value: voteToken })),
    [voteTokens]
  );

  /**
   * Memorising page tabs.
   */
  const tabs: PageTabContract[] = useMemo(() => {
    return [
      {
        title: BribeMarketsTabsEnum.INCENTIVES,
        onClick: () => setActiveTab(BribeMarketsTabsEnum.INCENTIVES),
        active: activeTab == BribeMarketsTabsEnum.INCENTIVES
        // icon: <MoneySatchIcon />
      }
      // {
      //   title: BribeMarketsTabsEnum.GOVERNANCE_POWER,
      //   onClick: () => setActiveTab(BribeMarketsTabsEnum.GOVERNANCE_POWER),
      //   active: activeTab == BribeMarketsTabsEnum.GOVERNANCE_POWER,
      //   icon: <MoneySatchIcon />
      // }
    ];
  }, [activeTab, setActiveTab]);

  return (
    <div className="page-bribe-markets">
      <div className="select-token-container">
        <div className="select-token-dropdown-prompt">
          <EcosystemIcon />
          <span className="multifarm-heading">Ecosystem</span>
        </div>
        <Select
          className="select-token-dropdown"
          options={voteTokenOptions}
          onChange={setActiveVoteToken}
          value={activeVoteToken}
        />
      </div>
      <PageTabs tabs={tabs} />
      <Component />
    </div>
  );
};
