import React from 'react';
import clsx from 'clsx';
// Icons
import { CaretUpIcon } from '../../assets/icons';
// Styles
import './table-pagination.scss';
export var TablePagination = function (_a) {
    var currentPage = _a.currentPage, pagesCount = _a.pagesCount, onPage = _a.onPage, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (React.createElement("div", { className: clsx('multifarm-widget-table-pagination', className), "data-first": currentPage === 1, "data-last": currentPage === pagesCount },
        React.createElement(CaretUpIcon, { onClick: function () {
                if (currentPage === 1) {
                    return onPage(1);
                }
                onPage(currentPage - 1);
            } }),
        React.createElement("p", { className: "multifarm-widget-table-pagination-text" },
            "Page ",
            currentPage,
            " of ",
            pagesCount),
        React.createElement(CaretUpIcon, { onClick: function () {
                if (currentPage === pagesCount) {
                    return onPage(pagesCount);
                }
                onPage(currentPage + 1);
            } })));
};
