import React from 'react';
import clsx from 'clsx';
// Icons
import { CheckmarkIcon } from '../../../assets/icons';
// Styles
import './checkbox.scss';
export var Checkbox = function (_a) {
    var onChange = _a.onChange, checked = _a.checked, disabled = _a.disabled, className = _a.className;
    return (React.createElement("div", { className: clsx('multifarm-widget-checkbox-wrapper', className) },
        React.createElement("label", null,
            React.createElement("div", { className: "multifarm-widget-checkbox", "data-active": checked, "data-disabled": disabled },
                React.createElement("input", { type: "checkbox", disabled: disabled, checked: checked, onChange: function (e) {
                        return onChange(e.currentTarget.checked);
                    } }),
                checked && React.createElement(CheckmarkIcon, null)))));
};
