import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, InfiniteTable, Link } from '@multifarm/widget-components';

// Components
import { GaugesOverviewHeader } from './GaugesOverviewHeader';
import { Button } from '../../../form/Button';
import { SortButtons } from '../../../sort-buttons';

// Context
import { useMultibribesContext } from '../../../../context/multibribes.context';

// Hooks - API
import { useGaugesInfinite } from '../../../../common/hooks/api';

// Static
import { GAUGES_TABLE } from './gauges-overview.static';
import { SortOrderEnum } from '../../../../common/static';

// Styles
import './gauges-overview.scss';

export const GaugesOverview: React.FC = () => {
  const { activeVoteToken: ecosystem } = useMultibribesContext();
  /**
   * State for managing gauges pagination
   */
  const [pagination, setPagination] = useState({
    maxPages: 1
  });

  /**
   * State to manage the voters for the selected ecosystem.
   */
  const [voters, setVoters] = useState<string[]>([]);

  /**
   * State for managing active voters for incentives filtering
   */
  const [activeVoters, setActiveVoters] = useState<string[]>([]);

  /**
   * State for managing the gagues filters
   */
  const [filters, setFilters] = useState<{
    searchQuery: string;
    platformName: string;
    sortBy: string;
    sortOrder: SortOrderEnum;
  }>({
    searchQuery: '',
    platformName: '',
    sortBy: '',
    sortOrder: SortOrderEnum.DESC
  });

  // Fetching the gauges for the ecosystem.
  const {
    data: gaugesData,
    maxPages,
    setSize,
    size
  } = useGaugesInfinite(ecosystem, {
    perPage: 20,
    searchQuery: filters.searchQuery,
    sortBy: filters.sortBy,
    sortOrder: filters.sortOrder,
    platformName: filters.platformName,
    // If all voters are selected, don't pass any to the query since the result will be the same, but the performance will be better.
    voters: activeVoters.length !== voters.length ? activeVoters : []
  });

  /**
   * A hook to reset the voters when the ecosystem changes.
   */
  useEffect(() => {
    if (voters.length === 0) {
      return;
    }
    setVoters([]);
    setActiveVoters([]);
  }, [ecosystem]);

  /**
   * A hook to load the initial voters for the selected ecosystem.
   */
  useEffect(() => {
    if (voters.length > 0 || !gaugesData) {
      return;
    }
    setVoters(gaugesData.voteTokens);
    if (gaugesData.voteTokens.length === 1) {
      setActiveVoters(gaugesData.voteTokens);
    }
  }, [gaugesData]);

  /**
   * A hook to load the initial max pages for the pagination.
   */
  useEffect(() => {
    if (maxPages < 0 || maxPages === pagination.maxPages) {
      return;
    }
    setPagination({ maxPages });
    setSize(1);
  }, [maxPages]);

  /**
   * A callback to handle active voters change.
   */
  const handleActiveVoters = useCallback(
    (voter: string): void => {
      if (voters.length <= 1) {
        return;
      }
      if (activeVoters.includes(voter)) {
        setActiveVoters(
          activeVoters.filter((activeVoter: string) => activeVoter !== voter)
        );
        return;
      }
      setActiveVoters([...activeVoters, voter]);
    },
    [activeVoters, voters]
  );

  /**
   * Memorising the presentation data.
   */
  const presentationGauges = useMemo(() => {
    if (!gaugesData?.gauges) {
      return [];
    }
    return gaugesData.gauges.map((gauge) => {
      gauge.buildPresentationData();
      return gauge;
    });
  }, [gaugesData]);

  /**
   * Memorising table columns.
   */
  const tableColumns = useMemo(() => {
    return [
      ...GAUGES_TABLE.staticColumns.map((item) => {
        if (!item.sortKey) {
          return item;
        }

        return {
          ...item,
          name: (
            <div className="sort-wrapper">
              {item.name}{' '}
              <SortButtons
                sortKey={item.sortKey}
                filters={filters}
                onSort={(sortState): void =>
                  setFilters((prev) => ({ ...prev, ...sortState }))
                }
              />
            </div>
          )
        };
      }),
      {
        name: '',
        key: 'actionLinks',
        format: (actionLinks: {
          investLink?: string;
          moreInfoLink?: string;
        }): React.ReactNode => {
          if (!actionLinks.investLink && !actionLinks.moreInfoLink) {
            return 'No available actions.';
          }
          return (
            <div className="actions-container">
              {actionLinks.investLink && (
                <Button
                  title="Vote"
                  onClick={(): void =>
                    window.open(actionLinks.investLink, '_blank')?.focus()
                  }
                />
              )}
              {actionLinks.moreInfoLink && (
                <Link url={actionLinks.moreInfoLink} content="More info" />
              )}
            </div>
          );
        },
        align: 'right' as const,
        wrap: true,
        width: '10%'
      }
    ];
  }, [pagination, filters]);

  return (
    <div className="page-bribe-markets-gagues-overview">
      <Card
        headerComponent={
          <GaugesOverviewHeader
            searchHandle={(searchQuery: string): void => {
              setFilters({ ...filters, searchQuery });
            }}
            voters={voters}
            activeVoters={activeVoters}
            voterHandle={handleActiveVoters}
            platformName={filters.platformName}
            filterHandle={(platformName: string): void => {
              setFilters({ ...filters, platformName });
            }}
          />
        }
        className="multibribes-card-transparent gauge-card"
      >
        <div className="page-bribe-markets-gagues-overview__content">
          <InfiniteTable
            columns={tableColumns}
            data={presentationGauges}
            initialHeight={400}
            hasMore={size < pagination.maxPages}
            fetchMore={(): void => setSize(size + 1)}
            loader={<div className="multifarm-infinite-loader">Loading...</div>}
          />
        </div>
      </Card>
    </div>
  );
};
