/**
 * Stringifies URL and the given query parameters.
 * @param url - Base URL
 * @param query - Query parameters.
 * @returns Stringified URL.
 */
export const stringifyURL = (url: string, query: any): string => {
  if (!query) return url;

  const queryParams = Object.keys(query).map((key: string) =>
    Array.isArray(query[key])
      ? buildQueryParamsFromArray(key, query[key])
      : `${key}=${query[key]}`
  );
  const queryString = queryParams.join('&');
  return `${url}?${queryString}`;
};

/**
 * Appends query paramaters of the given key to the query string.
 * @param key - Query string.
 * @param queryParam - Query param value.
 * @returns Formatter query string.
 */
const buildQueryParamsFromArray = (key: string, queryParam: any[]): string => {
  return queryParam.map((item) => `${key}=${item}`).join('&');
};
