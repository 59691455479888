import React from 'react';
import { TableColumn } from '@multifarm/widget-components';

// Utils
import { formatMoney } from '../../common/utils';

export const OVERIVEW_STATISTICS = {
  TOTAL_INCENTIVES_VOLUME: 'Total incentives volume',
  NUMBER_OF_PLATFORMS: '# of platforms',
  NUMBER_OF_VOTE_TOKENS: '# of vote tokens',
  NUMBER_OF_INCENTIVES: '# of incentives',
  TOTAL_LIQUIDITY: 'Total liquidity'
};

export const PLATFORMS_TABLE = ({
  onPlatformClick
}: {
  onPlatformClick: (platform: string) => void;
}): { columns: TableColumn[] } => ({
  columns: [
    {
      name: 'Platform',
      key: 'platform',
      format: (platform: string): React.ReactNode => (
        <span
          // TODO: Platform details load will be returned
          // className="clickable"
          onClick={(): void => onPlatformClick(platform)}
        >
          {platform}
        </span>
      ),
      align: 'left' as const,
      wrap: true,
      width: '10%'
    },
    {
      name: 'All Time Rewards',
      align: 'left' as const,
      key: 'rewardsAllTime',
      format: (tick: number): string => formatMoney(tick, 2, true),
      wrap: true,
      width: '10%'
    },
    {
      name: 'Current Rewards',
      align: 'left' as const,
      key: 'rewards',
      format: (tick: number): string => formatMoney(tick, 2, true),
      wrap: true,
      width: '10%'
    }
  ]
});
