import React from 'react';
import clsx from 'clsx';

// Components
import { MenuTabs } from './tabs/MenuTabs';

// Static
import { TabEnum } from '../../common/static';

// Styles
import './menu.scss';

type MenuProps = {
  activeTab: TabEnum;
  onTab: (tab: TabEnum) => void;
  open?: boolean;
};

export const Menu: React.FC<MenuProps> = ({
  activeTab,
  onTab,
  open = true
}) => {
  return (
    <div
      className={clsx('multibribes-menu', {
        'multibribes-menu-open': open,
        'multibribes-menu-closed': !open
      })}
    >
      <MenuTabs activeTab={activeTab} onTab={onTab} />
    </div>
  );
};
