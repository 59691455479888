import React from 'react';
import clsx from 'clsx';

// Styles
import './header-tab.scss';

type HeaderTabProps = {
  title: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  active?: boolean;
};

export const HeaderTab: React.FC<HeaderTabProps> = ({
  title,
  onClick,
  icon,
  active = false
}) => {
  return (
    <div
      className={clsx('page-header-tab', {
        'page-header-tab-active': active,
        clickable: !!onClick
      })}
      onClick={onClick}
    >
      <div
        className={clsx('icon-container', {
          ['icon-container-active']: active
        })}
      >
        {icon}
      </div>
      <span>{title}</span>
    </div>
  );
};
