import React from 'react';
import clsx from 'clsx';
// Styles
import './input.scss';
export var Input = function (_a) {
    var onChange = _a.onChange, _b = _a.value, value = _b === void 0 ? '' : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, title = _a.title, placeholder = _a.placeholder, className = _a.className;
    return (React.createElement("div", { className: clsx('multifarm-widget-input', className) },
        title && React.createElement("span", { className: "multifarm-widget-input-title" }, title),
        React.createElement("input", { value: value, className: "multifarm-widget-input-field", onChange: function (e) {
                return onChange(e.target.value);
            }, type: type, placeholder: placeholder })));
};
