import React from 'react';
import clsx from 'clsx';
// Components
import { CardHeader } from './CardHeader';
// Styles
import './card.scss';
export var Card = function (_a) {
    var children = _a.children, title = _a.title, titleComponent = _a.titleComponent, headerComponent = _a.headerComponent, subtitle = _a.subtitle, tooltip = _a.tooltip, className = _a.className;
    return (React.createElement("div", { className: clsx('multifarm-widget-card', className) },
        (title || titleComponent || headerComponent) && (React.createElement(CardHeader, { title: title, tooltip: tooltip, titleComponent: titleComponent, headerComponent: headerComponent, subtitle: subtitle })),
        children));
};
