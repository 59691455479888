import React from 'react';
import clsx from 'clsx';

// Styles
import './menu-toggle.scss';

type MenuToggleProps = {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
};

export const MenuToggle: React.FC<MenuToggleProps> = ({
  menuOpen,
  setMenuOpen
}) => {
  return (
    <div
      className={clsx('menu-toggle', {
        ['menu-toggle-open']: menuOpen
      })}
      onClick={(): void => setMenuOpen(!menuOpen)}
    >
      <div className="menu-toggle-line-top"></div>
      <div className="menu-toggle-line-middle">
        <div className="menu-toggle-line-middle-inner"></div>
      </div>
      <div className="menu-toggle-line-bottom"></div>
    </div>
  );
};
