import React from 'react';

// Styles
import './footer.scss';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="disclaimer">
        <p>
          VoteX is a community analytics tool and doesn’t generate any revenue.
          If you enjoy our services, please consider a donation to the following
          address &#10084;
        </p>
        <p className="address">0x485539d4570372870608c5223BEffa9E8303E469</p>
      </div>
    </footer>
  );
};
