import React from 'react';

// Components
import { GaugesOverview } from './gauges-overview/GaugesOverview';

// Styles
import './gauges.scss';

export const Gauges: React.FC = () => {
  return (
    <div className="page-bribe-markets-gagues">
      <GaugesOverview />
    </div>
  );
};
