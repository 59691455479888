import React from 'react';
import clsx from 'clsx';
// Assets
import { CaretUpIcon } from '../../assets/icons';
// Styles
import './accordion.scss';
export var Accordion = function (_a) {
    var title = _a.title, tooltip = _a.tooltip, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, onClick = _a.onClick, _c = _a.renderArrow, renderArrow = _c === void 0 ? true : _c, headerComponent = _a.headerComponent, children = _a.children, className = _a.className;
    if (!headerComponent && renderArrow) {
        headerComponent = React.createElement(CaretUpIcon, null);
    }
    return (React.createElement("div", { className: clsx('multifarm-widget-accordion', className) },
        React.createElement("div", { className: "multifarm-widget-accordion-summary", onClick: onClick, "data-open": isOpen },
            title ||
                (tooltip && (React.createElement("div", { className: "multifarm-widget-accordion-title" },
                    title,
                    tooltip))),
            headerComponent),
        isOpen && (React.createElement("div", { className: "multifarm-widget-accordion-content" }, children))));
};
