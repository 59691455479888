import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Bar, BarChart, CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// Components
import { LoadingPlaceholder, NoDataPlaceholder } from '../placeholders';
import { Legends, Tooltip as MultifarmTooltip } from './common';
// Utils
import { buildChartOptions, renderHorizonalGradients } from '../../common/utils';
// Styles
import './chart.scss';
export var HorizontalBarChart = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var data = _a.data, dataKeys = _a.dataKeys, yTickFormatter = _a.yTickFormatter, _0 = _a.yAxisDataKey, yAxisDataKey = _0 === void 0 ? 'category' : _0, xTickFormatter = _a.xTickFormatter, legends = _a.legends, colors = _a.colors, _1 = _a.gradientColors, gradientColors = _1 === void 0 ? false : _1, isLoading = _a.isLoading, _2 = _a.initialHeight, initialHeight = _2 === void 0 ? 200 : _2, options = _a.chartOptions, barOptions = _a.barOptions, _3 = _a.showTooltip, showTooltip = _3 === void 0 ? true : _3, tooltipOptions = _a.tooltipOptions, className = _a.className;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    var chartOptions = useMemo(function () { return buildChartOptions(options); }, [options]);
    return (React.createElement("div", { className: "multifarm-widget-chart-container", "data-legends": !!(legends === null || legends === void 0 ? void 0 : legends.length) },
        React.createElement("div", { className: clsx('multifarm-widget-chart', className), "data-height": initialHeight },
            React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                React.createElement(BarChart, { data: data, layout: "vertical", barCategoryGap: 1 },
                    React.createElement(YAxis, { type: (_b = chartOptions.yAxis) === null || _b === void 0 ? void 0 : _b.type, dataKey: yAxisDataKey, width: (_c = chartOptions.yAxis) === null || _c === void 0 ? void 0 : _c.width, tickFormatter: yTickFormatter, axisLine: (_d = chartOptions.yAxis) === null || _d === void 0 ? void 0 : _d.line, stroke: (_e = chartOptions.yAxis) === null || _e === void 0 ? void 0 : _e.stroke, interval: (_f = chartOptions.yAxis) === null || _f === void 0 ? void 0 : _f.interval, tick: (_g = chartOptions.yAxis) === null || _g === void 0 ? void 0 : _g.tick, tickLine: (_h = chartOptions.yAxis) === null || _h === void 0 ? void 0 : _h.tickLine, reversed: (_j = chartOptions.yAxis) === null || _j === void 0 ? void 0 : _j.reversed, padding: (_k = chartOptions.yAxis) === null || _k === void 0 ? void 0 : _k.padding, minTickGap: (_l = chartOptions.yAxis) === null || _l === void 0 ? void 0 : _l.minTickGap }, ((_m = chartOptions.yAxis) === null || _m === void 0 ? void 0 : _m.label) && (React.createElement(Label, { value: chartOptions.yAxis.label.value, position: chartOptions.yAxis.label.position, style: chartOptions.yAxis.label.styles, angle: chartOptions.yAxis.label.angle, offset: chartOptions.yAxis.label.offset }))),
                    React.createElement(XAxis, { type: (_o = chartOptions.xAxis) === null || _o === void 0 ? void 0 : _o.type, tickFormatter: xTickFormatter, axisLine: (_p = chartOptions.xAxis) === null || _p === void 0 ? void 0 : _p.line, stroke: (_q = chartOptions.xAxis) === null || _q === void 0 ? void 0 : _q.stroke, width: (_r = chartOptions.xAxis) === null || _r === void 0 ? void 0 : _r.width, orientation: (_s = chartOptions.xAxis) === null || _s === void 0 ? void 0 : _s.orientation, tick: (_t = chartOptions.xAxis) === null || _t === void 0 ? void 0 : _t.tick, tickLine: (_u = chartOptions.xAxis) === null || _u === void 0 ? void 0 : _u.tickLine, reversed: (_v = chartOptions.xAxis) === null || _v === void 0 ? void 0 : _v.reversed, padding: (_w = chartOptions.xAxis) === null || _w === void 0 ? void 0 : _w.padding, minTickGap: (_x = chartOptions.xAxis) === null || _x === void 0 ? void 0 : _x.minTickGap }),
                    ((_y = chartOptions.grid) === null || _y === void 0 ? void 0 : _y.enable) && (React.createElement(CartesianGrid, { vertical: chartOptions.grid.vertical, stroke: chartOptions.grid.stroke, opacity: chartOptions.grid.opacity, strokeDasharray: chartOptions.grid.strokeDasharray })),
                    ((_z = chartOptions.referenceLine) === null || _z === void 0 ? void 0 : _z.enable) && (React.createElement(ReferenceLine, { x: chartOptions.referenceLine.x, y: chartOptions.referenceLine.y, label: chartOptions.referenceLine.label, stroke: chartOptions.referenceLine.stroke, strokeWidth: chartOptions.referenceLine.strokeWidth, strokeOpacity: chartOptions.referenceLine.strokeOpacity, strokeDasharray: chartOptions.referenceLine.strokeDasharray })),
                    showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) {
                            var _a, _b;
                            var payload = (_b = (_a = props.payload) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.payload;
                            if (!payload)
                                return React.createElement(React.Fragment, null);
                            return (React.createElement(MultifarmTooltip, { data: payload, keys: dataKeys, colors: colors, revertKeys: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.revertKeys, formatTooltipKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipKey, formatTooltipValue: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipValue, categoryKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.categoryKey, formatCategory: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatCategory }));
                        } })),
                    renderHorizonalGradients(dataKeys, colors, gradientColors),
                    dataKeys.map(function (key, index) { return (React.createElement(Bar, { key: "bar-".concat(index), dataKey: key, fill: "url(#".concat(key, ")"), stackId: "1", maxBarSize: barOptions === null || barOptions === void 0 ? void 0 : barOptions.maxBarSize, radius: barOptions === null || barOptions === void 0 ? void 0 : barOptions.radius })); })))),
        legends && React.createElement(Legends, { legends: legends, colors: colors })));
};
