import React from 'react';
// Styles
import './card.scss';
export var CardHeader = function (_a) {
    var title = _a.title, titleComponent = _a.titleComponent, tooltip = _a.tooltip, headerComponent = _a.headerComponent, subtitle = _a.subtitle;
    return (React.createElement("div", { className: "multifarm-widget-card-header-container" },
        React.createElement("div", { className: "multifarm-widget-card-header" },
            (title || titleComponent) && (React.createElement("div", { className: "multifarm-widget-card-title-container" },
                title && (React.createElement("div", { className: "multifarm-widget-card-title" },
                    React.createElement("span", null, title),
                    tooltip)),
                titleComponent)),
            headerComponent),
        subtitle));
};
