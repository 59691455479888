// Request DTO
import { PaginationDTO } from './pagination.dto';

// Static
import { SortOrderEnum } from '../../static';

export class GetGaugesRequestDTO extends PaginationDTO {
  sort?: string;
  sort_order?: '+' | '-';
  platform?: string;
  gauge_name?: string;
  vote_token_symbol?: string[];
  constructor(data: {
    page: number;
    perPage: number;
    sortBy: string;
    sortOrder: SortOrderEnum;
    searchQuery: string;
    platformName: string;
    voters: string[];
  }) {
    super(data);
    if (data.sortBy) {
      this.sort = data.sortBy;
      this.sort_order = data.sortOrder === SortOrderEnum.ASC ? '+' : '-';
    }
    if (data.searchQuery) {
      this.gauge_name = data.searchQuery;
    }
    if (data.platformName) {
      this.platform = data.platformName;
    }
    if (data.voters.length > 0) {
      this.vote_token_symbol = data.voters;
    }
  }
}
