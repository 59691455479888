export const PLATFORM_DETAILS_STATISTICS = {
  TOTAL_VOTE_REWARDS: 'Total Vote Rewards',
  NUMBER_OF_MARKETS: '# of markets',
  NUMBER_OF_GAUGES: '# of gauges'
};

export const PLATFORM_DETAILS_CHART = {
  keys: ['value'],
  data: [
    {
      date: '2022-10-01',
      value: 545000
    },
    {
      date: '2022-10-02',
      value: 565000
    },
    {
      date: '2022-10-03',
      value: 555000
    },
    {
      date: '2022-10-04',
      value: 563000
    },
    {
      date: '2022-10-05',
      value: 569000
    },
    {
      date: '2022-10-06',
      value: 575000
    },
    {
      date: '2022-10-07',
      value: 597000
    }
  ]
};
