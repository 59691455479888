import React from 'react';
import clsx from 'clsx';

// Styles
import './page-tab.scss';

export type PageTabContract = {
  title: string;
  onClick: () => void;
  icon?: React.ReactNode;
  active?: boolean;
};

export const PageTab: React.FC<PageTabContract> = ({
  title,
  onClick,
  icon,
  active = false
}) => {
  return (
    <div className={clsx('page-tab', { active })} onClick={onClick}>
      {icon}
      <span>{title}</span>
    </div>
  );
};
