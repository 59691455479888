import { RawGovernance } from '../../contracts';

export class Governance {
  id: string;
  name: string;
  address: string;
  blockchain?: string;
  website?: string;
  tokenHoldings: number;
  tokenHoldingsUsd: number;
  tokenHoldingsEth: number;
  about?: string;
  riskDescription?: string;
  investmentThesis?: string;

  constructor(data: RawGovernance) {
    this.id = data._id;
    this.name = data.name;
    this.address = data.address;
    this.blockchain = data.blockchain || undefined;
    this.website = data.website || undefined;
    this.tokenHoldings = data.token_holdings;
    this.tokenHoldingsUsd = data.token_holdings_usd;
    this.tokenHoldingsEth = data.token_holdings_eth;
    this.about = data.about || 'About placeholder data...';
    this.riskDescription =
      data.risk_description || 'Risk description placeholder data...';
    this.investmentThesis =
      data.about || 'Investment thesis placeholder data...';
  }
}
