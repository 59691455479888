import { Arguments } from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';

// API - Service
import { buildRoute, fetcher } from '../../services';

// Contracts
import { InfiniteAPIResponse, RawGaugesResponse } from '../../contracts';

// Models
import { Gauge, GaugesResponse } from '../../models/domain';

// Request DTO
import { GetGaugesRequestDTO } from '../../models/request';

// Static
import { SortOrderEnum } from '../../static';

type GetGaugesParams = {
  perPage?: number;
  searchQuery?: string;
  sortBy?: string;
  sortOrder?: SortOrderEnum;
  platformName?: string;
  voters?: string[];
};

const getKey = (
  ecosystem?: string,
  options?: GetGaugesParams
): SWRInfiniteKeyLoader<RawGaugesResponse, Arguments> => {
  return (pageIndex, previousPageData) => {
    if (
      !ecosystem ||
      (previousPageData && previousPageData.gauges.length === 0)
    ) {
      return null;
    }

    return buildRoute(
      `/gauges/${ecosystem}`,
      new GetGaugesRequestDTO({
        page: pageIndex + 1,
        perPage: options?.perPage || 5,
        sortBy: options?.sortBy || '',
        sortOrder: options?.sortOrder || SortOrderEnum.DESC,
        searchQuery: options?.searchQuery || '',
        platformName: options?.platformName || '',
        voters: options?.voters || []
      })
    );
  };
};

/**
 * @param ecosystem - Ecosystem's name.
 * @returns Gauges for the given ecosystem.
 */
export const useGaugesInfinite = (
  ecosystem?: string,
  options?: GetGaugesParams
): InfiniteAPIResponse<GaugesResponse | undefined> => {
  const { data, error, size, setSize } = useSWRInfinite<RawGaugesResponse>(
    getKey(ecosystem, options),
    fetcher
  );

  const rawResponses: RawGaugesResponse[] | undefined = data;

  let response: GaugesResponse | undefined;
  let maxPages = -1;
  const isLoading = !data && !error;

  if (rawResponses && rawResponses.length > 0) {
    maxPages = rawResponses[0].max_pages;
    response = {
      voteTokens: rawResponses[0].vote_tokens,
      gauges: ([] as Gauge[]).concat(
        ...rawResponses.map((rawResponse) =>
          rawResponse.gauges.map((gauge) => new Gauge(gauge))
        )
      )
    };
  }

  return {
    isLoading,
    data: response,
    maxPages,
    setSize,
    size
  };
};
