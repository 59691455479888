import React from 'react';
import clsx from 'clsx';
// Components
import { Card } from '../Card';
import { PieChart } from '../../charts';
import { DataTable } from '../../table';
import { LoadingPlaceholder, NoDataPlaceholder } from '../../placeholders';
// Styles
import './pie-chart-table-card.scss';
export var PieChartTableCard = function (_a) {
    var data = _a.data, chartDataKey = _a.chartDataKey, tableColumns = _a.tableColumns, colors = _a.colors, isLoading = _a.isLoading, className = _a.className, title = _a.title, _b = _a.initialHeight, initialHeight = _b === void 0 ? 200 : _b, cardTooltip = _a.cardTooltip, showChartTooltip = _a.showChartTooltip, chartTooltipOptions = _a.chartTooltipOptions;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    return (React.createElement(Card, { className: clsx('multifarm-widget-pie-chart-table-card', className), title: title, tooltip: cardTooltip },
        React.createElement("div", { className: "multifarm-widget-pie-chart-table-card-container" },
            React.createElement(PieChart, { data: data, dataKey: chartDataKey, colors: colors, showTooltip: showChartTooltip, tooltipOptions: chartTooltipOptions, initialHeight: initialHeight }),
            React.createElement(DataTable, { data: data, columns: tableColumns, colors: colors }))));
};
