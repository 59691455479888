import React from 'react';
import clsx from 'clsx';
// Components
import { LoadingPlaceholder, NoDataPlaceholder } from '../placeholders';
// Styles
import './table.scss';
export var Table = function (_a) {
    var columns = _a.columns, isLoading = _a.isLoading, isEmpty = _a.isEmpty, _b = _a.initialHeight, initialHeight = _b === void 0 ? 200 : _b, className = _a.className, children = _a.children;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (isEmpty) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    return (React.createElement("table", { className: clsx('multifarm-widget-table', className) },
        React.createElement("thead", null,
            React.createElement("tr", null, columns.map(function (column, index) { return (React.createElement("th", { key: "column-".concat(index), "data-wrap": column.wrap, className: "multifarm-widget-table-head-cell", align: column.align || 'left', style: { width: column.width } }, column.name)); }))),
        React.createElement("tbody", null, children)));
};
