import React from 'react';
import clsx from 'clsx';
import { Cell, Pie, PieChart as RePieChart, ResponsiveContainer, Tooltip } from 'recharts';
// Components
import { LoadingPlaceholder, NoDataPlaceholder } from '../placeholders';
import { Tooltip as MultifarmTooltip } from './common';
// Styles
import './chart.scss';
export var PieChart = function (_a) {
    var data = _a.data, dataKey = _a.dataKey, colors = _a.colors, noDataPlaceholder = _a.noDataPlaceholder, _b = _a.innerRadius, innerRadius = _b === void 0 ? '60%' : _b, _c = _a.outerRadius, outerRadius = _c === void 0 ? '100%' : _c, stroke = _a.stroke, details = _a.details, legends = _a.legends, isLoading = _a.isLoading, loadingPlaceholder = _a.loadingPlaceholder, _d = _a.initialHeight, initialHeight = _d === void 0 ? 200 : _d, _e = _a.showTooltip, showTooltip = _e === void 0 ? true : _e, tooltipOptions = _a.tooltipOptions, className = _a.className;
    if (isLoading) {
        return (React.createElement(LoadingPlaceholder, { minHeight: initialHeight, text: loadingPlaceholder }));
    }
    if (!data.length) {
        return (React.createElement(NoDataPlaceholder, { minHeight: initialHeight, text: noDataPlaceholder }));
    }
    return (React.createElement("div", { className: "multifarm-widget-chart-container", "data-legends": !!(legends === null || legends === void 0 ? void 0 : legends.length) },
        details,
        React.createElement("div", { className: clsx('multifarm-widget-chart', className), "data-height": initialHeight },
            React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                React.createElement(RePieChart, { data: data },
                    React.createElement(Pie, { data: data, dataKey: dataKey, outerRadius: outerRadius, innerRadius: innerRadius, paddingAngle: 0, stroke: stroke }, data.map(function (_, index) { return (React.createElement(Cell, { key: index, fill: colors[index] })); })),
                    showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) {
                            var _a, _b;
                            var payload = (_b = (_a = props.payload) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.payload;
                            var keys = (tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.tooltipKeys) || [dataKey];
                            if (!payload)
                                return null;
                            return (React.createElement(MultifarmTooltip, { data: payload, keys: keys, revertKeys: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.revertKeys, formatTooltipKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipKey, formatTooltipValue: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipValue, categoryKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.categoryKey, formatCategory: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatCategory }));
                        } })))))));
};
