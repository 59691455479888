import React from 'react';

// Styles
import './button.scss';

type ButtonProps = {
  title: string;
  onClick?: () => void;
};

export const Button: React.FC<ButtonProps> = ({ title, onClick }) => {
  return (
    <button className="multibribes-button" onClick={onClick}>
      {title}
    </button>
  );
};
