import { intervalToDuration, isBefore } from 'date-fns';

/**
 * @param date
 * @returns Boolean indicating if the given date is valid.
 */
export const isValidDate = (date: Date): boolean => {
  return !isNaN(date.getTime());
};

/**
 * @param baseDate - Base date that should be compared to the next date.
 * @param nextDate - A date to compare the base date to.
 * @returns A string representation of time remaning from the current moment to the given one.
 */
export const getDifferenceInStringFormat = (
  baseDate: Date,
  nextDate: Date
): string => {
  if (isBefore(nextDate, baseDate)) {
    return '00h 00m';
  }

  const duration = intervalToDuration({ start: baseDate, end: nextDate });
  if (duration.years && duration.years > 0) {
    const noun = duration.years > 1 ? 'years' : 'year';
    return `${duration.years} ${noun}`;
  }

  if (duration.months && duration.months > 0) {
    const noun = duration.months > 1 ? 'months' : 'month';
    return `${duration.months} ${noun}`;
  }

  if (duration.days && duration.days > 0) {
    // Weeks handle
    if (duration.days >= 7) {
      const weeks = Math.round(duration.days / 7);
      const noun = weeks > 1 ? 'weeks' : 'week';
      return `${weeks} ${noun}`;
    }

    // Days handle
    const noun = duration.days > 1 ? 'days' : 'days';
    return `${duration.days} ${noun}`;
  }

  // Handle the difference that is less than 1 day.
  const hours = duration.hours
    ? `${duration.hours.toString().padStart(2, '0')}h`
    : '00h';
  const minutes = duration.minutes
    ? `${duration.minutes.toString().padStart(2, '0')}m`
    : '00m';

  return `${hours} ${minutes}`;
};
