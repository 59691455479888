// Contracts
import { Tab } from '../contracts';

// Static
import { RangeTypeEnum, RANGE_OPTION_NAMES } from '../static';

/**
 * @param array
 * @returns The reversed array.
 */
export const reverse = (array: any[]): any[] => {
  return array.slice().reverse();
};

/**
 * Creates range options based on the pased range values.
 * @param ranges - Range values.
 * @returns Range options
 */
export const createRangeOptions = (
  ranges: RangeTypeEnum[]
): Tab<RangeTypeEnum>[] => {
  return ranges.map((range) => ({
    name: RANGE_OPTION_NAMES[range] ?? range,
    value: range
  }));
};

/**
 * Omits the empty keys from the given object.
 * @param object
 * @returns Object with keys that have values.
 */
export const omitEmpty = (object: Record<string, any>): Record<string, any> => {
  const filtered: any = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      filtered[key] = object[key];
    }
  });
  return filtered;
};

/**
 * Checks that the given value is not empty
 * @param value - Any given value.
 * @returns Boolean indicating that the value is not empty.
 */
export const isNotEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};
