import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';

// Styles
import './input.scss';

type InputProps = {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  title?: string;
  type?: HTMLInputTypeAttribute;
};

export const Input: React.FC<InputProps> = ({
  onChange,
  value = '',
  type = 'text',
  title,
  placeholder
}) => {
  return (
    <label className="multibribes-input">
      {title && <span className="multibribes-input-title">{title}</span>}
      <input
        value={value}
        className="multibribes-input-field"
        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
          onChange(e.target.value)
        }
        type={type}
        placeholder={placeholder}
      />
    </label>
  );
};
