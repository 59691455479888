import React, { useCallback, useMemo } from 'react';
// Utils
import { reverse } from '../../../common/utils';
// Styles
import './tooltip.scss';
export var Tooltip = function (_a) {
    var data = _a.data, keys = _a.keys, colors = _a.colors, separateTotalKey = _a.separateTotalKey, _b = _a.revertKeys, revertKeys = _b === void 0 ? false : _b, formatTooltipKey = _a.formatTooltipKey, formatTooltipValue = _a.formatTooltipValue, filterCallback = _a.filterCallback, _c = _a.categoryKey, categoryKey = _c === void 0 ? 'date' : _c, formatCategory = _a.formatCategory;
    // Trimming the colors
    if (colors) {
        colors = colors.slice(0, keys.length);
    }
    // Reverting the keys and colors
    if (revertKeys) {
        keys = reverse(keys);
        if (colors) {
            colors = reverse(colors);
        }
    }
    /**
     * Should footer be renderd?
     */
    var renderFooter = useMemo(function () {
        return data[categoryKey] || (separateTotalKey && data[separateTotalKey]);
    }, [data, categoryKey, separateTotalKey]);
    /**
     * A callback for rendering tooltip value.
     */
    var renderTooltipValue = useCallback(function (key) {
        return formatTooltipValue
            ? formatTooltipValue(data[key], key)
            : data[key];
    }, [data, formatTooltipValue]);
    /**
     * A callback for rendering tooltip key.
     */
    var renderTooltipKey = useCallback(function (key) {
        return formatTooltipKey ? formatTooltipKey(key) : key;
    }, [formatTooltipKey]);
    /**
     * Callback for rendering a tooltip tick.
     */
    var renderTooltipTick = useCallback(function (key, index) {
        if (!data[key] || (filterCallback && !filterCallback(data[key]))) {
            return;
        }
        return (React.createElement("div", { className: "multifarm-widget-chart-tooltip-tick", key: "tooltip-".concat(key) },
            colors && (React.createElement("span", { "data-color": 1, style: {
                    backgroundColor: colors[index]
                } })),
            React.createElement("span", null,
                React.createElement("span", { "data-key": 1 },
                    renderTooltipKey(key),
                    ":"),
                renderTooltipValue(key))));
    }, [data, filterCallback, renderTooltipValue, renderTooltipKey]);
    return (React.createElement("div", { className: "multifarm-widget-chart-tooltip" },
        keys.map(function (key, index) { return renderTooltipTick(key, index); }),
        renderFooter && (React.createElement("span", { "data-footer": 1 },
            separateTotalKey && (React.createElement("span", null,
                renderTooltipKey(separateTotalKey),
                ":",
                renderTooltipValue(separateTotalKey))),
            data[categoryKey] && (React.createElement("span", { className: "multifarm-widget-chart-tooltip-category" }, formatCategory
                ? formatCategory(data[categoryKey])
                : data[categoryKey]))))));
};
