import React from 'react';

// Components
// import { Button } from '../form/Button';
import { MenuToggle } from '../menu/toggle/MenuToggle';
import { HeaderTab } from './tabs/HeaderTab';

// Static
import { TabEnum, TAB_DETAILS } from '../../common/static';

// Assets
import VotexLogo from '../../assets/images/votex-logo-white.png';

// Styles
import './header.scss';

type HeaderProps = {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  activeTab: TabEnum;
};

export const Header: React.FC<HeaderProps> = ({
  menuOpen,
  setMenuOpen,
  activeTab
}) => {
  return (
    <div className="page-header">
      <div className="page-header-left-container">
        <img className="page-header-logo" alt="votex.fi" src={VotexLogo} />
        <MenuToggle menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        {!menuOpen && (
          <HeaderTab
            title={TAB_DETAILS[activeTab].title}
            icon={TAB_DETAILS[activeTab].icon}
            active
          />
        )}
      </div>
      {/* Commented out for the time being until the auth is setup */}
      {/* <div className="page-header-right-container">
        <Button title="Log Out" />
      </div> */}
    </div>
  );
};
