import React from 'react';
import { Card } from '@multifarm/widget-components';

// Models
import { TotalBribeStatistics } from '../../../common/models/domain';

// Static
import { OVERIVEW_STATISTICS } from '../overview.static';

// Utils
import { formatMoney, formatNumber } from '../../../common/utils';

// Styles
import './statistics.scss';

type StatisticsProps = {
  statistics?: Pick<
    TotalBribeStatistics,
    | 'gaugesCount'
    | 'marketsCount'
    | 'platformsCount'
    | 'totalVolume'
    | 'tvlStaked'
  >;
};

export const Statistics: React.FC<StatisticsProps> = ({ statistics }) => {
  return (
    <div className="page-overview-statistics">
      <Card
        className="multibribes-card"
        title={OVERIVEW_STATISTICS.TOTAL_INCENTIVES_VOLUME}
      >
        {formatMoney(statistics?.totalVolume)}
      </Card>
      <Card
        className="multibribes-card"
        title={OVERIVEW_STATISTICS.NUMBER_OF_PLATFORMS}
      >
        {formatNumber(statistics?.platformsCount, 0, true)}
      </Card>
      <Card
        className="multibribes-card"
        title={OVERIVEW_STATISTICS.NUMBER_OF_VOTE_TOKENS}
      >
        {/* TODO: Don't hardcode the number of vote tokens */}
        {formatNumber(5, 0, true)}
      </Card>
      <Card
        className="multibribes-card"
        title={OVERIVEW_STATISTICS.NUMBER_OF_INCENTIVES}
      >
        {formatNumber(statistics?.gaugesCount, 0, true)}
      </Card>
    </div>
  );
};
