import React from 'react';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
// Assets
import { InfoIcon } from '../../assets/icons';
// Styles
import './tooltip.scss';
export var InfoTooltip = function (_a) {
    var content = _a.content, _b = _a.maxWidth, maxWidth = _b === void 0 ? 350 : _b, tooltipClassName = _a.tooltipClassName, triggerClassName = _a.triggerClassName;
    return (React.createElement(Tippy, { interactive: true, appendTo: function () { return document.body; }, trigger: 'focusin click mouseenter focus', arrow: false, maxWidth: maxWidth, content: typeof content === 'string' ? (React.createElement("div", null,
            React.createElement("span", { dangerouslySetInnerHTML: { __html: content } }))) : (content), className: clsx('multifarm-widget-info-tooltip', tooltipClassName) },
        React.createElement("div", { onClick: function (e) {
                e.stopPropagation();
                e.preventDefault();
            }, className: clsx('multifarm-widget-info-tooltip-trigger', triggerClassName) },
            React.createElement(InfoIcon, null))));
};
