import React from 'react';

// Components
import {
  PlatformsTable,
  Statistics,
  TotalBribeVolumeChart
} from '../../components/overview';

// Hooks - API
import { useTotalBribeStats } from '../../common/hooks/api';

export const Overview: React.FC = () => {
  const { data: statisticsData } = useTotalBribeStats();
  return (
    <div className="page-overview">
      <Statistics statistics={statisticsData} />
      <TotalBribeVolumeChart data={statisticsData?.tvlStakedHistorical || []} />
      <PlatformsTable />
    </div>
  );
};
