// Contracts
import { RawAssetToken } from '../../contracts';

export class AssetToken {
  asset: string;
  description: string;
  contractUrl?: string;
  websiteUrl?: string;

  constructor(data: RawAssetToken) {
    this.asset = data.asset;
    this.description = data.description;
    this.contractUrl = data.contract_url;
    this.websiteUrl = data.website_url;
  }
}
