export const TOKEN_OPTIONS = [
  {
    value: 'cvx',
    label: 'CVX'
  },
  {
    value: 'crv',
    label: 'CRV'
  }
];

export const TOKEN_HOLDINGS_OVER_TIME_CHART = {
  keys: ['value'],
  data: [
    {
      date: '2022-10-01',
      value: 545000
    },
    {
      date: '2022-10-02',
      value: 565000
    },
    {
      date: '2022-10-03',
      value: 555000
    },
    {
      date: '2022-10-04',
      value: 563000
    },
    {
      date: '2022-10-05',
      value: 569000
    },
    {
      date: '2022-10-06',
      value: 575000
    },
    {
      date: '2022-10-07',
      value: 597000
    }
  ]
};

export const PIE_TABLE_COLUMNS = [
  {
    name: '',
    key: '',
    isColorColumn: true,
    width: '1%'
  },
  {
    name: 'Holders',
    key: 'holder',
    align: 'left' as const,
    wrap: true,
    width: '10%'
  },
  // {
  //   name: 'Blockchain',
  //   key: 'blockchain',
  //   align: 'left' as const,
  //   wrap: true,
  //   width: '10%'
  // },
  {
    name: 'Token Amount',
    align: 'center' as const,
    key: 'value',
    wrap: true,
    width: '10%'
  }
];

export const PIE_DATA = [
  {
    holder: 'Curve',
    blockchain: 'ETH',
    value: 2310
  },
  {
    holder: 'Balancer',
    blockchain: 'ETH',
    value: 1311
  },
  {
    holder: 'Multifarm',
    blockchain: 'ETH',
    value: 914
  }
];

export const PIE_COLORS = ['#F4BE37', '#D733FF', '#33BBFF'];
