import { useEffect, useState } from 'react';

/**
 * Hook used to apply value after a certain period of time.
 * @param value - Value to be applied.
 * @param delay - Miliseconds after the value will be applied.
 * @returns The applied value.
 */
export const useDebounce = (value: any, delay: number): any => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
