var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Bar, ComposedChart as ReComposedChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Line, Area } from 'recharts';
// Components
import { LoadingPlaceholder, NoDataPlaceholder } from '../placeholders';
import { Legends, Tooltip as MultifarmTooltip } from './common';
// Utils
import { buildChartOptions } from '../../common/utils';
// Styles
import './chart.scss';
export var ComposedChart = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var data = _a.data, dataOptions = _a.dataOptions, yTickFormatter = _a.yTickFormatter, xTickFormatter = _a.xTickFormatter, _v = _a.xAxisDataKey, xAxisDataKey = _v === void 0 ? 'date' : _v, legends = _a.legends, isLoading = _a.isLoading, _w = _a.initialHeight, initialHeight = _w === void 0 ? 200 : _w, chartOptions = _a.chartOptions, barOptions = _a.barOptions, _x = _a.showTooltip, showTooltip = _x === void 0 ? true : _x, tooltipOptions = _a.tooltipOptions, className = _a.className;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    chartOptions = buildChartOptions(chartOptions);
    var _y = useMemo(function () {
        var dataKeys = [];
        var colors = [];
        if (dataOptions.line) {
            dataKeys = dataOptions.line.keys;
            colors = dataOptions.line.colors;
        }
        if (dataOptions.bar) {
            dataKeys = __spreadArray(__spreadArray([], dataKeys, true), dataOptions.bar.keys, true);
            colors = __spreadArray(__spreadArray([], colors, true), dataOptions.bar.colors, true);
        }
        if (dataOptions.area) {
            dataKeys = __spreadArray(__spreadArray([], dataKeys, true), dataOptions.area.keys, true);
            colors = __spreadArray(__spreadArray([], colors, true), dataOptions.area.colors, true);
        }
        return { dataKeys: dataKeys, colors: colors };
    }, [dataOptions]), dataKeys = _y.dataKeys, colors = _y.colors;
    return (React.createElement("div", { className: "multifarm-widget-chart-container", "data-legends": !!(legends === null || legends === void 0 ? void 0 : legends.length) },
        React.createElement("div", { className: clsx('multifarm-widget-chart', className), "data-height": initialHeight },
            React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                React.createElement(ReComposedChart, { data: data, barGap: 0 },
                    React.createElement(YAxis, { type: "number", domain: ['auto', 'auto'], width: (_b = chartOptions.yAxis) === null || _b === void 0 ? void 0 : _b.width, tickFormatter: yTickFormatter, axisLine: (_c = chartOptions.yAxis) === null || _c === void 0 ? void 0 : _c.line, stroke: (_d = chartOptions.yAxis) === null || _d === void 0 ? void 0 : _d.stroke, interval: (_e = chartOptions.yAxis) === null || _e === void 0 ? void 0 : _e.interval, tick: (_f = chartOptions.yAxis) === null || _f === void 0 ? void 0 : _f.tick, tickLine: (_g = chartOptions.yAxis) === null || _g === void 0 ? void 0 : _g.tickLine, padding: { top: 20, bottom: 20 }, minTickGap: (_h = chartOptions.yAxis) === null || _h === void 0 ? void 0 : _h.minTickGap }),
                    React.createElement(XAxis, { dataKey: xAxisDataKey, tickFormatter: xTickFormatter, axisLine: (_j = chartOptions.xAxis) === null || _j === void 0 ? void 0 : _j.line, stroke: (_k = chartOptions.xAxis) === null || _k === void 0 ? void 0 : _k.stroke, width: (_l = chartOptions.xAxis) === null || _l === void 0 ? void 0 : _l.width, tick: (_m = chartOptions.xAxis) === null || _m === void 0 ? void 0 : _m.tick, tickLine: (_o = chartOptions.yAxis) === null || _o === void 0 ? void 0 : _o.tickLine, padding: { left: 20, right: 20 }, minTickGap: (_p = chartOptions.xAxis) === null || _p === void 0 ? void 0 : _p.minTickGap }),
                    ((_q = chartOptions.grid) === null || _q === void 0 ? void 0 : _q.enable) && (React.createElement(CartesianGrid, { vertical: chartOptions.grid.vertical, stroke: chartOptions.grid.stroke, opacity: chartOptions.grid.opacity })),
                    ((_r = chartOptions.referenceLine) === null || _r === void 0 ? void 0 : _r.enable) && (React.createElement(ReferenceLine, { y: chartOptions.referenceLine.y, stroke: chartOptions.referenceLine.stroke, strokeOpacity: chartOptions.referenceLine.strokeOpacity, strokeDasharray: chartOptions.referenceLine.strokeDasharray })),
                    showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) {
                            var _a, _b;
                            var payload = (_b = (_a = props.payload) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.payload;
                            if (!payload)
                                return React.createElement(React.Fragment, null);
                            return (React.createElement(MultifarmTooltip, { data: payload, keys: dataKeys, colors: colors, revertKeys: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.revertKeys, formatTooltipKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipKey, formatTooltipValue: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatTooltipValue, categoryKey: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.categoryKey, formatCategory: tooltipOptions === null || tooltipOptions === void 0 ? void 0 : tooltipOptions.formatCategory }));
                        } })), (_s = dataOptions.bar) === null || _s === void 0 ? void 0 :
                    _s.keys.map(function (key, index) {
                        var _a, _b;
                        return (React.createElement(Bar, { key: "bar-".concat(index), dataKey: key, fill: (_a = dataOptions.bar) === null || _a === void 0 ? void 0 : _a.colors[index], stackId: (_b = dataOptions.bar) === null || _b === void 0 ? void 0 : _b.stack, maxBarSize: barOptions === null || barOptions === void 0 ? void 0 : barOptions.maxBarSize, radius: barOptions === null || barOptions === void 0 ? void 0 : barOptions.radius }));
                    }), (_t = dataOptions.line) === null || _t === void 0 ? void 0 :
                    _t.keys.map(function (key, index) {
                        var _a;
                        return (React.createElement(Line, { key: "line-".concat(index), dataKey: key, stroke: (_a = dataOptions.line) === null || _a === void 0 ? void 0 : _a.colors[index], type: "monotoneX", unit: "M", strokeLinecap: "round", strokeWidth: 2, lightingColor: "#000", dot: false, legendType: "none" }));
                    }), (_u = dataOptions.area) === null || _u === void 0 ? void 0 :
                    _u.keys.map(function (key, index) {
                        var _a, _b;
                        return (React.createElement(Area, { key: "area-".concat(index), dataKey: key, fill: (_a = dataOptions.area) === null || _a === void 0 ? void 0 : _a.colors[index], stackId: (_b = dataOptions.area) === null || _b === void 0 ? void 0 : _b.stack, connectNulls: true, type: "monotone" }));
                    })))),
        legends && React.createElement(Legends, { legends: legends, colors: colors })));
};
