import React, { useMemo, useState } from 'react';
import { Card, AreaChart, Tabs } from '@multifarm/widget-components';

// Contracts
import { HistoricalValue } from '../../../common/contracts';

// Utils
import {
  capitalizeFirstLetter,
  formatDate,
  formatMoney,
  getRangeOptions,
  parseRangeChartData
} from '../../../common/utils';

// Hooks
import { useChartOptions } from '../../../common/hooks/useChartOptions';

// Static
import { BASE_CHART_KEYS, RangeTypeEnum } from '../../../common/static';

type VoteRewardsChartProps = {
  data: HistoricalValue[];
};

const yTickFormatter = (tick: number): string => formatMoney(tick, 2, true);

export const VoteRewardsChart: React.FC<VoteRewardsChartProps> = ({ data }) => {
  const [range, setRange] = useState(RangeTypeEnum['14D']);

  /**
   * Memorising the available ranges.
   */
  const ranges = useMemo(() => {
    return getRangeOptions(data);
  }, [data]);

  /**
   * Memorising the ranged chart data.
   */
  const chartData = useMemo(() => {
    return parseRangeChartData(data, range);
  }, [range, data]);

  const chartOptions = useChartOptions({
    data: chartData,
    formatter: yTickFormatter
  });

  return (
    <div className="page-platform-details-vote-rewards-chart">
      <Card
        className="multibribes-card"
        title="Vote Rewards"
        headerComponent={
          <Tabs
            tabs={ranges}
            value={range}
            onChange={(value: string): void => {
              setRange(value as RangeTypeEnum);
            }}
            large
          />
        }
      >
        <AreaChart
          data={chartData}
          dataKeys={BASE_CHART_KEYS}
          yTickFormatter={yTickFormatter}
          xTickFormatter={(tick: string): string => formatDate(tick)}
          colors={['#32BBFF']}
          gradientColors={true}
          chartOptions={chartOptions}
          tooltipOptions={{
            formatTooltipKey: (tick: string): string =>
              capitalizeFirstLetter(tick),
            formatTooltipValue: (tick: number): string => formatMoney(tick)
          }}
          className="borderless-grid"
        />
      </Card>
    </div>
  );
};
