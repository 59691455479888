import React, { useCallback } from 'react';
import { DataTable } from '@multifarm/widget-components';

// Hooks
import { useMultibribesContext } from '../../../context/multibribes.context';

// Static
import { PLATFORMS_TABLE } from '../overview.static';

// Stlyes
import './platforms-table.scss';

export const PlatformsTable: React.FC = () => {
  const { platforms, activePlatform, setActivePlatform } =
    useMultibribesContext();

  const selectPlatform = useCallback(
    (platformName: string): void => {
      if (activePlatform?.platform === platformName) {
        return;
      }
      const platform = platforms.find(
        (platform) => platform.platform === platformName
      );
      setActivePlatform(platform);
    },
    [activePlatform, setActivePlatform, platforms]
  );
  return (
    <div className="page-overview-platforms-table">
      <DataTable
        columns={PLATFORMS_TABLE({ onPlatformClick: selectPlatform }).columns}
        data={platforms}
      />
    </div>
  );
};
