import useSwr from 'swr';

// API - Service
import { fetcher } from '../../services';

// Contracts
import { BaseAPIResponse, RawGovernance } from '../../contracts';

// Models
import { Governance } from '../../models/domain';

/**
 * @param voteToken - Vote token's name.
 * @returns TODO
 */
export const useGovernance = (
  voteToken?: string
): BaseAPIResponse<Governance | undefined> => {
  let route = null;
  if (voteToken) {
    route = `/governance/${voteToken}`;
  }
  const { data, error } = useSwr(route, fetcher);

  let governance: Governance | undefined = undefined;
  const rawResponse: RawGovernance | null | undefined = data;
  if (rawResponse) {
    governance = new Governance(rawResponse);
  }

  const isLoading = data === undefined && !error;

  return {
    isLoading,
    data: governance
  };
};
