import React, { useState } from 'react';
import { Card, AreaChart, Tabs, Select } from '@multifarm/widget-components';

// Utils
import {
  capitalizeFirstLetter,
  formatDate,
  formatPercent
} from '../../../../common/utils';

// Static
import {
  CURRENCIES,
  CurrencyEnum,
  RANGES,
  RangeTypeEnum
} from '../../../../common/static';
import {
  TOKEN_OPTIONS,
  TOKEN_HOLDINGS_OVER_TIME_CHART
} from '../governance-power.static';

// Hooks
import { useChartOptions } from '../../../../common/hooks/useChartOptions';

// Assets
import { InfoIcon, GoToIcon } from '../../../../assets/icons';

// Styles
import './token-holdings-over-time-chart.scss';

type HeaderComponentProps = {
  currency: CurrencyEnum;
  onCurrency: (value: CurrencyEnum) => void;
  range: RangeTypeEnum;
  onRange: (value: RangeTypeEnum) => void;
  token: string;
  onToken: (value: string) => void;
};
const HeaderComponent: React.FC<HeaderComponentProps> = ({
  currency,
  onCurrency,
  range,
  onRange,
  token,
  onToken
}) => {
  return (
    <div className="token-holding-header-component">
      <Select
        options={TOKEN_OPTIONS}
        value={token}
        onChange={(value: string): void => {
          onToken(value);
        }}
        className="token-select"
      />
      <Tabs
        tabs={CURRENCIES}
        value={currency}
        onChange={(value: string): void => {
          onCurrency(value as CurrencyEnum);
        }}
        large
      />
      <Tabs
        tabs={RANGES}
        value={range}
        onChange={(value: string): void => {
          onRange(value as RangeTypeEnum);
        }}
        large
      />
      <GoToIcon />
    </div>
  );
};

const yTickFormatter = (tick: number): string => formatPercent(tick / 10000);

export const TokenHoldingsOverTimeChart: React.FC = () => {
  const [token, setToken] = useState('cvx');
  const [currency, setCurrency] = useState(CurrencyEnum.USD);
  const [range, setRange] = useState(RangeTypeEnum['14D']);

  const chartOptions = useChartOptions({
    data: TOKEN_HOLDINGS_OVER_TIME_CHART.data,
    formatter: yTickFormatter
  });

  return (
    <div className="page-bribe-markets-governance-power-token-holdings-chart">
      <Card
        className="multibribes-card"
        title="Token Holdings Over Time"
        titleComponent={<InfoIcon />}
        headerComponent={
          <HeaderComponent
            currency={currency}
            onCurrency={setCurrency}
            range={range}
            onRange={setRange}
            token={token}
            onToken={setToken}
          />
        }
      >
        <AreaChart
          data={TOKEN_HOLDINGS_OVER_TIME_CHART.data}
          dataKeys={TOKEN_HOLDINGS_OVER_TIME_CHART.keys}
          yTickFormatter={yTickFormatter}
          xTickFormatter={(tick: string): string => formatDate(tick)}
          colors={['#32BBFF']}
          gradientColors={true}
          chartOptions={chartOptions}
          tooltipOptions={{
            formatTooltipKey: (tick: string): string =>
              capitalizeFirstLetter(tick),
            formatTooltipValue: (tick: number): string =>
              formatPercent(tick / 10000)
          }}
          className="borderless-grid"
        />
      </Card>
    </div>
  );
};
