import React from 'react';
import { PieChartTableCard } from '@multifarm/widget-components';

// Components
import { DetailsCards } from './details-cards/DetailsCards';
import { TokenHoldingsOverTimeChart } from './token-holdings-over-time-chart/TokenHoldingsOverTimeChart';
import { LiquidWrappers } from './liquid-wrappers/LiquidWrappers';

// Context
import { useMultibribesContext } from '../../../context/multibribes.context';

// Static
import {
  PIE_DATA,
  PIE_TABLE_COLUMNS,
  PIE_COLORS
} from './governance-power.static';

// Styles
import './governance-power.scss';
import { useGovernance } from '../../../common/hooks/api';

export const GovernancePover: React.FC = () => {
  const { activeVoteToken } = useMultibribesContext();
  const { data: governance, isLoading } = useGovernance(activeVoteToken);

  // TODO: Better handle
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!governance) {
    return <div>No data</div>;
  }

  return (
    <div className="page-bribe-markets-governance-power">
      <DetailsCards details={governance} />
      <TokenHoldingsOverTimeChart />
      <PieChartTableCard
        className="multibribes-card governance-power-pie"
        data={PIE_DATA}
        tableColumns={PIE_TABLE_COLUMNS}
        chartDataKey="value"
        colors={PIE_COLORS}
      />
      <LiquidWrappers />
    </div>
  );
};
