import React from 'react';

// Assets
import {
  OverviewIcon,
  PlatformDetailsIcon,
  BribeMarketsIcon
} from '../../assets/icons';

export enum TabEnum {
  BRIBE_MARKETS = 'bribe-markets',
  OVERVIEW = 'overview',
  PLATFORM_DETAILS = 'platform-details'
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc'
}

export const TAB_DETAILS: Record<
  TabEnum,
  {
    title: string;
    icon?: React.ReactNode;
  }
> = {
  [TabEnum.BRIBE_MARKETS]: {
    title: 'Vote Incentives',
    icon: <BribeMarketsIcon />
  },
  [TabEnum.OVERVIEW]: {
    title: 'Overview',
    icon: <OverviewIcon />
  },
  [TabEnum.PLATFORM_DETAILS]: {
    title: 'Platform Details',
    icon: <PlatformDetailsIcon />
  }
};
