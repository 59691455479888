import clsx from 'clsx';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// Components
import { NoDataPlaceholder } from '../placeholders';
// Styles
import './infinite-table.scss';
export var InfiniteTable = function (_a) {
    var data = _a.data, columns = _a.columns, hasMore = _a.hasMore, fetchMore = _a.fetchMore, colors = _a.colors, isEmpty = _a.isEmpty, _b = _a.initialHeight, initialHeight = _b === void 0 ? 200 : _b, className = _a.className, _c = _a.loader, loader = _c === void 0 ? 'Loading...' : _c;
    if (isEmpty) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    return (React.createElement("div", { className: clsx('multifarm-widget-infinite-table', className) },
        React.createElement("div", { className: "multifarm-widget-infinite-table-header" }, columns.map(function (column, index) { return (React.createElement("div", { key: "column-".concat(index), className: "multifarm-widget-infinite-table-header-cell", "data-wrap": column.wrap, style: { width: column.width, textAlign: column.align || 'left' } }, column.name)); })),
        React.createElement(InfiniteScroll, { dataLength: data.length, hasMore: hasMore, next: fetchMore, loader: loader }, data.map(function (row, rowIndex) { return (React.createElement("div", { key: rowIndex, className: "multifarm-widget-infinite-table-row" }, columns.map(function (column, index) { return (React.createElement("div", { key: index, className: "multifarm-widget-infinite-table-cell", style: {
                width: column.width,
                textAlign: column.align || 'left'
            }, "data-wrap": column.wrap },
            colors && column.isColorColumn && (React.createElement("span", { className: "multifarm-widget-table-cell-color", style: { backgroundColor: colors[rowIndex] } })),
            column.format
                ? column.format(row[column.key])
                : row[column.key])); }))); }))));
};
