var TICK_DEFAULT = {
    fill: 'white'
};
var AXIS_DEFUALT = {
    width: 50,
    interval: 0,
    line: false,
    tickLine: false,
    tick: TICK_DEFAULT,
    reversed: false
};
export var CHART_OPTIONS_DEFAULT = {
    xAxis: AXIS_DEFUALT,
    yAxis: AXIS_DEFUALT
};
