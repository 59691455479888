import React, { ReactNode, useContext, useMemo, useState } from 'react';

// Models
import { Platform } from '../common/models/domain';

type TMultibribesContext = {
  platforms: Platform[];
  setPlatforms: (platforms: Platform[]) => void;
  activePlatform?: Platform;
  setActivePlatform: (platform?: Platform) => void;

  voteTokens: string[];
  setVoteTokens: (voteTokens: string[]) => void;
  activeVoteToken?: string;
  setActiveVoteToken: (voteToken?: string) => void;
};

const MultibribesContext = React.createContext<TMultibribesContext | null>(
  null
);

export const useMultibribesContext = (): TMultibribesContext => {
  const context = useContext(MultibribesContext);
  if (!context) {
    throw new Error(
      'Multibribes context must be used inside of the context provider.'
    );
  }
  return context;
};

export const MultibribesContextProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [activePlatform, setActivePlatform] = useState<Platform | undefined>(
    undefined
  );

  const [voteTokens, setVoteTokens] = useState<string[]>([]);
  const [activeVoteToken, setActiveVoteToken] = useState<string | undefined>(
    undefined
  );

  const context: TMultibribesContext = useMemo(
    () => ({
      platforms,
      setPlatforms,
      activePlatform,
      setActivePlatform,
      voteTokens,
      setVoteTokens,
      activeVoteToken,
      setActiveVoteToken
    }),
    [
      platforms,
      setPlatforms,
      activePlatform,
      setActivePlatform,
      voteTokens,
      setVoteTokens,
      activeVoteToken,
      setActiveVoteToken
    ]
  );
  return (
    <MultibribesContext.Provider value={context}>
      {children}
    </MultibribesContext.Provider>
  );
};
