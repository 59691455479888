import React from 'react';
import clsx from 'clsx';

// Styles
import './menu-tab.scss';

type MenuTabProps = {
  title: string;
  onClick: () => void;
  icon?: React.ReactNode;
  active?: boolean;
};

export const MenuTab: React.FC<MenuTabProps> = ({
  title,
  onClick,
  icon,
  active = false
}) => {
  return (
    <div className={clsx('menu-tab', { active })} onClick={onClick}>
      <div
        className={clsx('icon-container', {
          ['icon-container-active']: active
        })}
      >
        {icon}
      </div>
      <span>{title}</span>
    </div>
  );
};
