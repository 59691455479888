import React from 'react';
import clsx from 'clsx';

// Static
import { SortOrderEnum } from '../../common/static';

// Icons
import { ChevronUp } from '../../assets/icons';

// Styles
import './sort-buttons.scss';

type SortButtonsProps = {
  sortKey: string;
  filters: { sortBy: string; sortOrder: SortOrderEnum };
  onSort: (sortOptions: { sortBy: string; sortOrder: SortOrderEnum }) => void;
};

export const SortButtons: React.FC<SortButtonsProps> = ({
  sortKey,
  onSort,
  filters
}) => (
  <div>
    <button
      className={clsx('votex-sort-button', {
        'votex-sort-button__active':
          filters.sortBy === sortKey && filters.sortOrder === SortOrderEnum.ASC
      })}
      type="button"
      onClick={(): void =>
        onSort({ sortBy: sortKey, sortOrder: SortOrderEnum.ASC })
      }
    >
      <ChevronUp />
    </button>
    <button
      type="button"
      className={clsx('votex-sort-button votex-sort-button__rotated', {
        'votex-sort-button__active':
          filters.sortBy === sortKey && filters.sortOrder === SortOrderEnum.DESC
      })}
      onClick={(): void =>
        onSort({ sortBy: sortKey, sortOrder: SortOrderEnum.DESC })
      }
    >
      <ChevronUp />
    </button>
  </div>
);
