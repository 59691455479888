import React from 'react';
import { Card } from '@multifarm/widget-components';

// Models
import { Platform } from '../../../common/models/domain';

// Static
import { PLATFORM_DETAILS_STATISTICS } from '../platform-details.static';

// Utils
import { formatMoney, formatNumber } from '../../../common/utils';

// Styles
import './statistics.scss';

type StatisticsProps = {
  statistics?: Pick<Platform, 'marketsCount' | 'gaugesCount' | 'tvlStaked'>;
};

export const Statistics: React.FC<StatisticsProps> = ({ statistics }) => {
  return (
    <div className="page-platform-details-statistics">
      <Card
        className="multibribes-card"
        title={PLATFORM_DETAILS_STATISTICS.TOTAL_VOTE_REWARDS}
      >
        {formatMoney(statistics?.tvlStaked, 2, true)}
      </Card>
      <Card
        className="multibribes-card"
        title={PLATFORM_DETAILS_STATISTICS.NUMBER_OF_MARKETS}
      >
        {formatNumber(statistics?.marketsCount, 0, true)}
      </Card>
      <Card
        className="multibribes-card"
        title={PLATFORM_DETAILS_STATISTICS.NUMBER_OF_GAUGES}
      >
        {formatNumber(statistics?.gaugesCount, 0, true)}
      </Card>
    </div>
  );
};
