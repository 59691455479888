import React from 'react';

// Components
import { PageTab, PageTabContract } from './PageTab';

// Styles
import './page-tabs.scss';

type PageTabsProps = {
  tabs: PageTabContract[];
};
export const PageTabs: React.FC<PageTabsProps> = ({ tabs }) => {
  return (
    <div className="page-tabs">
      {tabs.map((tab, index) => (
        <PageTab key={`tab-${index}`} {...tab} />
      ))}
    </div>
  );
};
