// Utils
import { omitEmpty, stringifyURL } from '../../utils';

/**
 * Coomonly used API fetcher.
 * @param url - Endpoint URL
 * @param params - Request parameters
 * @returns Response from the server
 */
export const fetcher = async (url: string, params: any): Promise<any> => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + url, {
      ...params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();

    if (json?.error === 'Not Authorized!') {
      throw new Error(json?.error);
    }

    return json;
  } catch (e) {
    throw e;
  }
};

/**
 * Stringifies the request params.
 * @param {string} route - Route endpoint.
 * @param {Record<string, any>} params - Data to add to query params.
 * @returns Stringified url.
 */
export const buildRoute = (
  route: string,
  params: Record<string, any>
): string => {
  return stringifyURL(route, omitEmpty(params));
};
