var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// Static
import { CHART_OPTIONS_DEFAULT } from '../../components/charts/static';
/**
 * @param key - Key to create a gradient id from. This is usualy a data access key for the chart.
 * @returns Gradient ID.
 */
export var getGradientId = function (key) {
    // Gradients fail if there are blank spaces in them.
    return key.replaceAll(' ', '-');
};
/**
 * Renders horizontal gradients.
 * @param {string[]} dataKeys - Data keys user
 * @param {string[]} colors
 * @param {boolean} isGradient - Boolean indicating if color gradient should be present, or should the colors be solid.
 * @returns Gradients definition.
 */
export var renderHorizonalGradients = function (dataKeys, colors, isGradient) {
    return (React.createElement("defs", null, dataKeys.map(function (key, index) {
        return (React.createElement("linearGradient", { key: index, id: getGradientId(key), x1: "1", y1: "0", x2: "0", y2: "0" },
            React.createElement("stop", { offset: isGradient ? '0%' : '100%', stopColor: colors[index], stopOpacity: 1 }),
            React.createElement("stop", { offset: "100%", stopColor: colors[index], stopOpacity: 0.1 })));
    })));
};
/**
 * Renders vertical gradients.
 * @param {string[]} dataKeys - Data keys user
 * @param {string[]} colors
 * @param {boolean} isGradient - Boolean indicating if color gradient should be present, or should the colors be solid.
 * @returns Gradients definition.
 */
export var renderVerticalGradients = function (dataKeys, colors, isGradient) {
    return (React.createElement("defs", null, dataKeys.map(function (key, index) {
        return (React.createElement("linearGradient", { key: index, id: getGradientId(key), x1: "0", y1: "0", x2: "0", y2: "1" },
            React.createElement("stop", { offset: isGradient ? '0%' : '100%', stopColor: colors[index], stopOpacity: 1 }),
            React.createElement("stop", { offset: "100%", stopColor: colors[index], stopOpacity: 0.1 })));
    })));
};
/**
 * Overrides the default options with the passed chart options.
 */
export var buildChartOptions = function (chartOptions) {
    if (!chartOptions) {
        return CHART_OPTIONS_DEFAULT;
    }
    return __assign(__assign(__assign({}, CHART_OPTIONS_DEFAULT), chartOptions), { yAxis: buildAxisOptions(CHART_OPTIONS_DEFAULT.yAxis, chartOptions.yAxis), xAxis: buildAxisOptions(CHART_OPTIONS_DEFAULT.xAxis, chartOptions.xAxis) });
};
/**
 * Overrides the default options with the passed chart options.
 */
export var buildAxisOptions = function (defaultAxisOptions, axisOptions) {
    return __assign(__assign(__assign({}, defaultAxisOptions), axisOptions), { tick: __assign(__assign({}, defaultAxisOptions.tick), axisOptions === null || axisOptions === void 0 ? void 0 : axisOptions.tick) });
};
