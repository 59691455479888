import React from 'react';
import ReactDOM from 'react-dom/client';

// Context
import { MultibribesContextProvider } from './context/multibribes.context';

// Components
import { MultiBribes } from './MultiBribes';

// Styles
import '@multifarm/widget-components/dist/widget-components.css';
import './styles/global.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MultibribesContextProvider>
      <MultiBribes />
    </MultibribesContextProvider>
  </React.StrictMode>
);
