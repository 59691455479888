var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
import ReactSlider from 'react-slider';
// Styles
import './slider.scss';
/**
 * Default function for marks rendering.
 * @param props - Props that are passed by the react slider library that should be spread on the thumb's parent component.
 * @returns The mark element to be rendered.
 */
var renderMarkDefault = function (props) {
    return React.createElement("span", __assign({}, props),
        props.key,
        "%");
};
/**
 * Default function for thumb renreding.
 * @param props - Props that are passed by the react slider library that should be spread on the thumb's parent component.
 * @param state - Current state of the thumb indicating its previous and current value.
 * @returns The thumb element to be rendered.
 */
var renderThumbDefault = function (props, state) {
    return (React.createElement("span", __assign({}, props),
        React.createElement("p", { className: "multifarm-widget-slider__thumb-text" },
            state.valueNow,
            "%")));
};
export var Slider = function (_a) {
    var value = _a.value, onChange = _a.onChange, _b = _a.minValue, minValue = _b === void 0 ? 0 : _b, _c = _a.maxValue, maxValue = _c === void 0 ? 100 : _c, marks = _a.marks, className = _a.className, _d = _a.renderThumb, renderThumb = _d === void 0 ? renderThumbDefault : _d, _e = _a.renderMark, renderMark = _e === void 0 ? renderMarkDefault : _e;
    return (React.createElement(ReactSlider, { className: clsx('multifarm-widget-slider', className), thumbClassName: "multifarm-widget-slider__thumb", trackClassName: "multifarm-widget-slider__track", markClassName: "multifarm-widget-slider__mark", marks: marks, min: minValue, max: maxValue, value: value, onChange: onChange, renderThumb: renderThumb, renderMark: renderMark }));
};
