// Contracts
import { Tab } from '../contracts/tabs.contracts';

export const DEFAULT_DATE_FORMAT = 'MMM dd';
export const EXPANDED_DATE_FORMAT = 'dd MMM YYY';
export const DATE_FORMAT_SECONDARY = 'dd.MM.YYYY';
export const API_DATE_FORMAT_PRIMARY = 'yyyy.MM.dd';
export const API_DATE_FORMAT_SECONDARY = 'yyyy-MM-dd';
export const CHART_TOOLTIP_DATE_PRIMARY = 'MMMM dd, yyyy';

export enum RangeTypeEnum {
  '7D' = '7d',
  '14D' = '14d',
  '30D' = '30d',
  '90D' = '90d',
  '180D' = '180d',
  '1Y' = '1y',
  'MAX' = 'max'
}

export const RANGE_OPTION_NAMES = {
  [RangeTypeEnum['7D']]: '7d',
  [RangeTypeEnum['14D']]: '14d',
  [RangeTypeEnum['30D']]: '30d',
  [RangeTypeEnum['90D']]: '90d',
  [RangeTypeEnum['180D']]: '180d',
  [RangeTypeEnum['1Y']]: '1y',
  [RangeTypeEnum.MAX]: 'Max'
};

export const RANGES: Tab<RangeTypeEnum>[] = [
  { name: RANGE_OPTION_NAMES[RangeTypeEnum['7D']], value: RangeTypeEnum['7D'] },
  {
    name: RANGE_OPTION_NAMES[RangeTypeEnum['14D']],
    value: RangeTypeEnum['14D']
  },
  {
    name: RANGE_OPTION_NAMES[RangeTypeEnum['30D']],
    value: RangeTypeEnum['30D']
  },
  {
    name: RANGE_OPTION_NAMES[RangeTypeEnum['90D']],
    value: RangeTypeEnum['90D']
  },
  {
    name: RANGE_OPTION_NAMES[RangeTypeEnum['180D']],
    value: RangeTypeEnum['180D']
  },
  { name: RANGE_OPTION_NAMES[RangeTypeEnum['1Y']], value: RangeTypeEnum['1Y'] },
  { name: RANGE_OPTION_NAMES[RangeTypeEnum['MAX']], value: RangeTypeEnum.MAX }
];

export enum CurrencyEnum {
  USD = 'USD',
  ETH = 'ETH'
}

export const CURRENCIES: Tab<CurrencyEnum>[] = [
  { name: 'USD', value: CurrencyEnum.USD },
  { name: 'ETH', value: CurrencyEnum.ETH }
];
