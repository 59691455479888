import React from 'react';
import { Card } from '@multifarm/widget-components';

// Components
import { LiquidWrapper } from './LiquidWrapper';

// Assets
import ConvexLogo from '../../../../assets/images/convex-logo.png';
import YearnLogo from '../../../../assets/images/yearn-logo.png';
import StakeLogo from '../../../../assets/images/stake-logo.png';

export const LiquidWrappers: React.FC = () => {
  return (
    <div className="page-bribe-markets-governance-power-liquid-wrappers">
      <Card className="multibribes-card" title="Liquid Wrappers">
        <LiquidWrapper
          wrapper={{ name: 'Convex (cvxCRV)', icon: <img src={ConvexLogo} /> }}
        />
        <LiquidWrapper
          wrapper={{ name: 'Yearn (yveCRV)', icon: <img src={YearnLogo} /> }}
        />
        <LiquidWrapper
          wrapper={{ name: 'Stake DAO (sdCRV)', icon: <img src={StakeLogo} /> }}
        />
      </Card>
    </div>
  );
};
