import React from 'react';

// Contracts
import { RawGauge, RawGaugesResponse } from '../../contracts';

// Utils
import {
  formatMoney,
  formatNumber,
  formatPercent,
  getDifferenceInStringFormat,
  isValidDate
} from '../../utils';

export type GaugePresentationData = {
  valuePerVote?: React.ReactNode | string;
  duration?: string;
  // NOTE: The following presentation data is deprecated but is left in case we need it again since we are in early development stages.
  apr?: React.ReactNode | string;
  remainingTime?: string;
  remainingRewards?: React.ReactNode | string;
};

export class Gauge {
  id: string;
  averageApr: number;
  blockchain: string;
  endDate: string;
  gaugeName: string;
  investLink?: string;
  platform: string;
  protocol: string;
  rewards: number;
  rewardsUsd: number;
  startDate: string;
  valuePerVote: number;
  voteTokenSymbol: string;
  actionLinks: {
    investLink?: string;
    moreInfoLink?: string;
  };

  /** Custom build property used to display gauge data in a custom way. */
  presentation: GaugePresentationData = {};

  constructor(data: RawGauge) {
    this.id = data._id;
    this.averageApr = data.average_apr;
    this.blockchain = data.blockchain;
    this.endDate = data.end_date;
    this.gaugeName = data.gauge_name;
    this.investLink = data.invest_link;
    this.platform = data.platform;
    this.protocol = data.protocol;
    this.rewards = data.rewards;
    this.rewardsUsd = data.rewards_usd;
    this.startDate = data.start_date;
    this.valuePerVote = data.value_per_vote;
    this.voteTokenSymbol = data.vote_token_symbol;
    this.actionLinks = {
      investLink: data.invest_link,
      moreInfoLink: data.more_info_link
    };
  }

  /**
   * Builds presentation data for the gauge.
   */
  buildPresentationData(): void {
    this.buildDurationPresentation();
    this.buildValuePerVotePresentation();
  }

  /**
   * Builds value per vote presentation data.
   */
  private buildValuePerVotePresentation(): void {
    if (this.valuePerVote <= 0 && this.voteTokenSymbol === '') {
      this.presentation.valuePerVote = 'N/A';
      return;
    }

    this.presentation.valuePerVote = (
      <p>{`${formatMoney(this.valuePerVote, 3, true)}/${
        this.voteTokenSymbol
      }`}</p>
    );
  }

  /**
   * Builds duration presentation data by calculating difference between the end and the start date.
   */
  private buildDurationPresentation(): void {
    if (this.startDate === '' || this.endDate === '') {
      this.presentation.duration = 'N/A';
      return;
    }

    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      this.presentation.duration = 'N/A';
      return;
    }

    this.presentation.duration = getDifferenceInStringFormat(
      startDate,
      endDate
    );
  }

  /**
   * NOTE: The following presentation function is deprecated but is left in case we need it again since we are in early development stages.
   * Builds APR presentation data.
   */
  private buildAprPresentation(): void {
    if (
      this.averageApr <= 0 &&
      this.valuePerVote <= 0 &&
      this.voteTokenSymbol === ''
    ) {
      this.presentation.apr = 'N/A';
      return;
    }

    this.presentation.apr = (
      <div>
        {this.averageApr > 0 && <p>{formatPercent(this.averageApr)}</p>}
        {this.valuePerVote > 0 && this.voteTokenSymbol !== '' && (
          <p>{`${formatMoney(this.valuePerVote, 3, true)}/${
            this.voteTokenSymbol
          }`}</p>
        )}
      </div>
    );
  }

  /**
   * NOTE: The following presentation function is deprecated but is left in case we need it again since we are in early development stages.
   * Builds remaining time presentation data by calculating difference between the end and the current date.
   */
  private buildRemainingTimePresentation(): void {
    if (this.endDate === '') {
      this.presentation.remainingTime = 'N/A';
      return;
    }

    const endDate = new Date(this.endDate);
    if (!isValidDate(endDate)) {
      this.presentation.remainingTime = 'N/A';
      return;
    }

    this.presentation.remainingTime = getDifferenceInStringFormat(
      new Date(),
      endDate
    );
  }

  /**
   * NOTE: The following presentation function is deprecated but is left in case we need it again since we are in early development stages.
   * Builds remaining rewards presentation data.
   */
  private buildRemainingRewardsPresentation(): void {
    if (this.rewards <= 0 && this.rewardsUsd <= 0) {
      this.presentation.remainingRewards = 'N/A';
      return;
    }

    this.presentation.remainingRewards = (
      <div>
        {this.rewards > 0 && <p>{formatNumber(this.rewards)}</p>}
        {this.rewardsUsd > 0 && <p>{formatMoney(this.rewardsUsd)}</p>}
      </div>
    );
  }
}

export class GaugesResponse {
  gauges: Gauge[];
  voteTokens: string[];

  constructor(data: RawGaugesResponse) {
    this.gauges = data.gauges.map((rawGauge: RawGauge) => new Gauge(rawGauge));
    this.voteTokens = data.vote_tokens.map((voteToken: string) => voteToken);
  }
}
