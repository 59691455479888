import {
  isAfter,
  subWeeks,
  subMonths,
  subYears,
  differenceInDays
} from 'date-fns';

// Contracts
import { TypeWithDate, Tab } from '../contracts';

// Static
import { RangeTypeEnum } from '../static';

// Utils
import { createRangeOptions } from './global.utils';

export const calcTickWidth = (
  tick: number,
  formatter: (value: number) => string
): number => {
  return `${formatter(tick)}`.length * 9;
};

export const calcAxisWidth = (
  data: any[],
  formatter: (value: number) => string,
  minWidth = 60
): number => {
  return Math.max(
    calcTickWidth(
      Math.max(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...data.map(({ date: _, ...row }) =>
          Math.max(...Object.values<number>(row))
        )
      ),
      formatter
    ),
    minWidth
  );
};

/**
 * Determines if the given tick should be filtered using the provided callback function.
 * @param tick - Chart's data tick.
 * @param filter - A callback function to filter the tick by.
 * @returns Should tick be filtered boolean.
 */
const filterDate = (
  tick: TypeWithDate,
  filter?: (date: string) => boolean
): boolean => {
  return filter ? filter(tick.date) : false;
};

/**
 * Checks if the given tick holds a value greater than 0 for at least one of the given keys.
 * @param tick
 * @param keys
 * @returns Should tick be filtered boolean.
 */
const filterKeys = (tick: any, keys: string[]): boolean => {
  if (keys.length === 0) return true;
  for (const key of keys) {
    if (tick[key] && tick[key] > 0) {
      return true;
    }
  }
  return false;
};

/**
 * A map of date filter utils for each used range.
 */
const DATE_RANGE_FILTERS: Record<
  RangeTypeEnum,
  ((date: string) => boolean) | undefined
> = {
  [RangeTypeEnum['7D']]: (date: string): boolean =>
    isAfter(new Date(date), subWeeks(new Date(), 1)),
  [RangeTypeEnum['14D']]: (date: string): boolean =>
    isAfter(new Date(date), subWeeks(new Date(), 2)),
  [RangeTypeEnum['30D']]: (date: string): boolean =>
    isAfter(new Date(date), subMonths(new Date(), 1)),
  [RangeTypeEnum['90D']]: (date: string): boolean =>
    isAfter(new Date(date), subMonths(new Date(), 3)),
  [RangeTypeEnum['180D']]: (date: string): boolean =>
    isAfter(new Date(date), subMonths(new Date(), 6)),
  [RangeTypeEnum['1Y']]: (date: string): boolean =>
    isAfter(new Date(date), subYears(new Date(), 1)),
  [RangeTypeEnum['MAX']]: undefined
};

/**
 * Filters the given tick by the date range and the provided keys.
 * @param tick - Chart's data tick.
 * @param range - Chart's given range.
 * @param keys - An optional parameter used to additionally filter the data by the keys that hold the value. If passed, the function will check if the row has a value greated than 0 for at least one of the given keys.
 * @returns Should tick be filtered boolean.
 */
const filterTick = (
  tick: TypeWithDate,
  range: RangeTypeEnum,
  keys: string[] = []
): boolean => {
  return filterDate(tick, DATE_RANGE_FILTERS[range]) && filterKeys(tick, keys);
};

/**
 * A util function used to parse the given data by range.
 * @param data - Array of data that should be parsed by range.
 * @param range - Chart's given range.
 * @param keys - An optional parameter used to additionally filter the data by the keys that hold the value. If passed, the function will check if the row has a value greated than 0 for at least one of the given keys.
 * @returns Parsed chart data.
 */
export const parseRangeChartData = (
  data: TypeWithDate[],
  range: RangeTypeEnum,
  keys: string[] = []
): any[] => {
  switch (range) {
    case RangeTypeEnum['7D']:
    case RangeTypeEnum['14D']:
    case RangeTypeEnum['30D']:
    case RangeTypeEnum['90D']:
    case RangeTypeEnum['180D']:
    case RangeTypeEnum['1Y']:
      return data.filter((tick) => filterTick(tick, range, keys));
    case RangeTypeEnum['MAX']:
      if (keys.length !== 0) {
        return data.filter((row) => filterKeys(row, keys));
      }
      return data;
    default:
      return data;
  }
};

/**
 * Determines only range options that are applicable to the given historical data.
 * @param data - Array containing hitorical data.
 * @returns Range options array based on the provided historical data.
 */
export const getRangeOptions = (data: TypeWithDate[]): Tab<RangeTypeEnum>[] => {
  if (data.length === 0) {
    return [];
  }
  const firstElement = data[0];
  const lastElement = data[data.length - 1];
  const firstDate = new Date(firstElement.date);
  const lastDate = new Date(lastElement.date);
  const minDate = isAfter(firstDate, lastDate) ? lastDate : firstDate;
  const daysDifference = differenceInDays(new Date(), minDate);
  let ranges = [RangeTypeEnum['7D']];
  if (daysDifference > 7) {
    ranges = [...ranges, RangeTypeEnum['14D']];
    if (daysDifference > 14) {
      ranges = [...ranges, RangeTypeEnum['30D']];
      if (daysDifference > 30) {
        ranges = [...ranges, RangeTypeEnum['90D']];
        if (daysDifference > 90) {
          ranges = [...ranges, RangeTypeEnum['180D']];
          if (daysDifference > 180) {
            ranges = [...ranges, RangeTypeEnum['1Y']];
          }
        }
      }
    }
  }
  ranges = [...ranges, RangeTypeEnum['MAX']];
  return createRangeOptions(ranges);
};
