import clsx from 'clsx';
import React from 'react';
// Utils
import { reverse } from '../../../common/utils';
// Styles
import './legends.scss';
export var Legends = function (_a) {
    var className = _a.className, legends = _a.legends, _b = _a.revertLegends, revertLegends = _b === void 0 ? false : _b, formatLegend = _a.formatLegend, colors = _a.colors;
    // Trimming the colors
    colors = colors.slice(0, legends.length);
    if (revertLegends) {
        legends = reverse(legends);
        colors = reverse(colors);
    }
    return (React.createElement("div", { className: clsx('multifarm-widget-line-chart-legends', className) }, legends.map(function (legend, index) { return (React.createElement("div", { className: "multifarm-widget-line-chart-legend", key: "legend-".concat(index) },
        React.createElement("span", { "data-color": 1, style: { backgroundColor: colors[index] } }),
        React.createElement("p", { "data-colored": 1 },
            React.createElement("span", { "data-title": 1 }, formatLegend ? formatLegend(legend) : legend)))); })));
};
